import {Stack, List, Text, useTheme, FontIcon} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import i18n from 'i18next';
import {formatDateTime, DateTimeFormat} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getListClassNames} from 'styles';
import {useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery} from '../hooks/workbenchItems.generated';

type VkoParagraphsWorkbenchItemsProps = {halfHeight?: boolean; title?: string};

export const VkoParagraphsWorkbenchItems: React.FC<
	VkoParagraphsWorkbenchItemsProps
> = ({halfHeight = false, title}) => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'VkoParagraphsWorkbenchItems',
	});
	const {data, loading} = useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery();

	const items = React.useMemo(
		() => data?.vkoWorkbenchRegulatoryDocumentParagraphs || [],
		[data],
	);

	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const onRenderCell = (item: any): JSX.Element => {
		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Stack>
						<Text>
							{item?.regulatoryDocumentParagraph?.parent?.regulation?.name}
						</Text>
						<Text>{item?.regulatoryDocumentParagraph?.parent?.name}</Text>
						<Link
							to={`/regulations/${item?.regulatoryDocumentParagraph?.parent?.regulation?.id}/${item?.regulatoryDocumentParagraph?.parent?.id}/paragraphs`}
							className={classNames.link}
						>
							<div className={classNames.itemName}>
								{item?.regulatoryDocumentParagraph?.enumeration}
							</div>
						</Link>
						<Text>
							{t('VexCluster')} {item?.vexClusterName}
						</Text>
						<Text>
							{t('Keyword')} {item?.keywordName}
						</Text>
						{item?.rejectKeywordReason ? (
							<Text>
								{t('RejectKeywordReason')} {item?.rejectKeywordReason}
							</Text>
						) : (
							item?.rejectReason && (
								<Text>
									{t('RejectReason')} {item?.rejectReason}
								</Text>
							)
						)}
					</Stack>
					<div className={classNames.itemSubtext}>
						{formatDateTime(
							new Date(item?.regulatoryDocumentParagraph?.createdAt),
							i18n,
							DateTimeFormat.DateMonthWeekday,
						)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<LoadWrapper loading={loading}>
			<div className={classNames.workbenchContainer}>
				<Stack horizontal horizontalAlign='space-between'>
					<Stack horizontal>
						<Stack.Item>
							<Text className={classNames.workbench}>{title}</Text>
						</Stack.Item>
						<Stack.Item>
							<Text className={classNames.itemCount}>
								{items.length > 30 ? `30+` : items.length}
							</Text>
						</Stack.Item>
					</Stack>
					<Stack>
						<Stack.Item>
							<FontIcon
								iconName='OpenInNewWindow'
								className={classNames.iconStyles}
							/>
						</Stack.Item>
					</Stack>
				</Stack>
				<div className={classNames.container} data-is-scrollable>
					<List items={items} onRenderCell={onRenderCell} />
				</div>
			</div>
		</LoadWrapper>
	);
};
