import {
	ActionButton,
	CommandButton,
	FontIcon,
	IIconProps,
	IStackStyles,
	Label,
	Link as FLuentLink,
	mergeStyleSets,
	Persona,
	PersonaSize,
	Separator,
	Stack,
	Text,
	Theme,
	TooltipHost,
	useTheme,
	DirectionalHint,
} from '@fluentui/react';
import {ChangeHistory, ChangeHistoryProps} from 'components/ChangeHistory';
import {
	ArrayValues,
	ReferenceField,
} from 'components/EntityList/ColumnRenderers';
import {RichtextDisplay} from 'components/RichtextDisplay';
import {EnumDropdown, EnumDropdownProps} from 'components/hookForms';
import {formatDateTime} from 'i18n/localeDateFormat';
import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
	AttachmentCategory,
	AttachmentRef,
	AuditLog,
	BlobRef,
	DocumentReference,
	PhaseDate,
	User,
} from 'types';
import {ArrayField} from './ArrayField';
import {BasicArrayFieldProps} from './DetailsSection.types';
import {ArrayFieldWrapper} from './ArrayFieldWrapper';
import {HistoryField} from './HistoryField';
import {parseTooltipNewlines} from 'helpers/tooltips';

export interface BasicFieldInfo {
	label: string;
	data: any;
	anonymous?: boolean;
	audit?: boolean;
	auditLogData?: AuditLog[];
	auditFieldName?: string;
	disclaimer?: string;
	enumProps?: EnumDropdownProps<any>;
	isLoading?: boolean;
}

export type DateFieldValue = Date | null | undefined;

export interface DetailsField extends BasicFieldInfo {
	type?:
		| 'text'
		| 'boolean'
		| 'array'
		| 'stringarray'
		| 'date'
		| 'richtext'
		| 'phase'
		| 'personas'
		| 'attachments'
		| 'references'
		| 'requirementRef'
		| 'enumDropdown'
		| 'history';
	/**
	 * Implementation notes:
	 *
	 * - We accept a renderer instead of a JSX.Element because the former allows
	 *   us to automatically pass certain fields. If we accepted a JSX.Element,
	 *   developers would have to pass those fields to their field's component
	 *   each time they create a new row.
	 */
	render?: (field: BasicFieldInfo) => JSX.Element;
	tooltipContent?: string;
}

export interface DetailsSectionProps {
	title: string;
	initialFieldRows: DetailsField[][];
	titleWithAudit?: boolean;
	viewMoreFieldRows?: DetailsField[][];
	auditLogData?: ChangeHistoryProps['data'];
	isLoading?: boolean;
}

const TextField: React.FC<{
	label: string;
	text: string;
	audit?: boolean;
	auditFieldName?: string;
	auditLogData?: any;
}> = ({label, text, audit, auditFieldName, auditLogData}) => {
	return (
		<div>
			<Stack horizontal>
				<Label>{label}</Label>
				{audit && (
					<ChangeHistory
						data={auditLogData}
						auditFieldName={auditFieldName || ''}
						renderOldValue={true}
					/>
				)}
			</Stack>
			<Text>{text}</Text>
		</div>
	);
};

const BooleanField: React.FC<{
	label: string;
	data: boolean;
	audit?: boolean;
	auditFieldName?: string;
	auditLogData?: any;
}> = ({label, data, audit, auditFieldName, auditLogData}) => {
	return (
		<div>
			<Stack horizontal>
				<Label>{label}</Label>
				{audit && (
					<ChangeHistory
						data={auditLogData}
						auditFieldName={auditFieldName || ''}
					/>
				)}
			</Stack>
			<FontIcon
				iconName={data === true ? 'CheckMark' : 'Cancel'}
				style={{color: data === true ? 'green' : 'red'}}
			/>
		</div>
	);
};

const StringArrayField: React.FC<BasicArrayFieldProps> = ({
	data,
	limit,
	...other
}) => {
	return (
		<ArrayFieldWrapper {...other}>
			<ArrayValues array={data} limit={limit ?? 10} getValue={item => item} />
		</ArrayFieldWrapper>
	);
};

const RichtextField: React.FC<{
	label: string;
	text: string;
	t: TFunction;
	audit?: boolean;
	auditLogData?: any;
	auditFieldName?: string;
}> = ({label, text, t, audit, auditLogData, auditFieldName}) => {
	const theme = useTheme();
	const [viewSummary, setViewSummary] = React.useState<boolean>(
		text.length < 500,
	);

	return (
		<div>
			<Stack horizontal>
				<Label>{label}</Label>
				{audit && (
					<ChangeHistory
						data={auditLogData}
						auditFieldName={auditFieldName || ''}
						renderOldValue={true}
					/>
				)}
			</Stack>

			{text.length > 500 ? (
				<>
					<RichtextDisplay value={text} limit={viewSummary ? undefined : 500} />
					<ActionButton
						styles={{
							root: {
								color: theme.palette.themePrimary,
								textAlign: 'left',
								padding: 0,
							},
							label: {
								margin: 0,
							},
						}}
						text={viewSummary ? t('ViewLessButton') : t('ViewMoreButton')}
						onClick={() => setViewSummary(!viewSummary)}
					/>
				</>
			) : (
				<RichtextDisplay value={text} />
			)}
		</div>
	);
};

const DateField: React.FC<{
	label: string;
	data: string;
	audit?: boolean;
	auditLogData?: any;
	auditFieldName?: string;
	disclaimer?: string;
}> = ({label, data, audit, auditFieldName, auditLogData, disclaimer}) => {
	const {i18n} = useTranslation();
	const theme = useTheme();
	const classNames = mergeStyleSets({
		grey: [{color: theme.palette.neutralTertiary}],
	});
	return (
		<div>
			<Stack horizontal>
				<Label>{label}</Label>
				{audit && (
					<ChangeHistory
						data={auditLogData}
						auditFieldName={auditFieldName || ''}
						renderOldValue={true}
					/>
				)}
				{disclaimer && (
					<>
						<TooltipHost content={disclaimer}>
							<FontIcon
								style={{paddingTop: 7}}
								iconName='Important'
								className={classNames.grey}
							/>
						</TooltipHost>
					</>
				)}
			</Stack>
			<Text>{formatDateTime(new Date(data), i18n)}</Text>
		</div>
	);
};

const PhaseField: React.FC<{label: string; data: PhaseDate[]}> = ({
	label,
	data,
}) => {
	const {i18n} = useTranslation();
	const theme = useTheme();
	const classNames = mergeStyleSets({
		arrayWrapper: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		arrayItem: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
		},
	});
	return (
		<div>
			<Label>{label}</Label>
			<div className={classNames.arrayWrapper}>
				{data.map((d, i) => (
					<div key={i} className={classNames.arrayItem}>
						{formatDateTime(new Date(d.date), i18n)} ({d.status})
					</div>
				))}
			</div>
		</div>
	);
};

const PersonasField: React.FC<{
	label: string;
	data: User | User[];
	anonymous?: boolean;
	audit?: boolean;
	auditLogData?: any;
	auditFieldName?: string;
}> = ({label, data, anonymous, audit, auditLogData, auditFieldName}) => {
	const users = Array.isArray(data) ? data : [data];
	return (
		<div>
			{anonymous === true ? (
				<div>
					<Label>{label}</Label>
					<div>Anonym</div>
				</div>
			) : (
				<div>
					<Stack horizontal>
						<Label>{label}</Label>
						{audit && (
							<ChangeHistory
								auditFieldName={auditFieldName || ''}
								data={auditLogData}
							/>
						)}
					</Stack>
					{users.map((u, i) => (
						<Persona key={i} text={u.name} size={PersonaSize.size8} />
					))}
				</div>
			)}
		</div>
	);
};

type FieldsOfAttachmentCategory = Pick<AttachmentCategory, 'name'>;

export interface AttachmentOfAttachmentsField
	extends Pick<AttachmentRef, 'attachmentId'> {
	file: Pick<BlobRef, 'uri'>;
	category?: FieldsOfAttachmentCategory | null;
}

const AttachmentsField: React.FC<{
	label: string;
	attachments: AttachmentOfAttachmentsField[];
	audit?: boolean;
	auditFieldName?: string;
	auditLogData?: any;
}> = ({label, attachments}) => {
	return (
		<div>
			<Stack horizontal>
				<Stack>
					<Label>{label}</Label>
					{attachments.map((attachment, i) => (
						<Stack horizontal key={i} tokens={{childrenGap: 6}}>
							<FLuentLink download href={attachment.file.uri}>
								<FontIcon iconName='Attach' />
								{attachment.attachmentId}
							</FLuentLink>
							{attachment.category && (
								<Stack.Item>{`(${attachment.category?.name})`}</Stack.Item>
							)}
						</Stack>
					))}
				</Stack>
			</Stack>
		</div>
	);
};

const EnumDropdownField: React.FC<{
	label: string;
	data: any;
	enumProps: EnumDropdownProps<any>;
	audit?: boolean;
	auditFieldName?: string;
	auditLogData?: any;
}> = ({label, data, enumProps}) => {
	return (
		<div>
			<Stack horizontal>
				<Stack>
					<EnumDropdown {...enumProps} defaultValue={data} label={label} />
				</Stack>
			</Stack>
		</div>
	);
};

const ReferencesField: React.FC<{label: string; data: DocumentReference[]}> = ({
	label,
	data,
}) => {
	return (
		<>
			<Label>{label}</Label>
			<ReferenceField references={data} />
		</>
	);
};

const RequirementReferenceField: React.FC<{label: string; data: any}> = ({
	label,
	data,
}) => {
	const theme = useTheme();
	const {t} = useTranslation(['features/requirements', 'common/enums']);
	const {parent, childs} = data;

	return (
		<>
			<Label>{label}</Label>
			{parent && (
				<>
					<span>{t('previousRequirements')}: </span>
					<br />
					<Link
						style={{
							color: theme.palette.themePrimary,
							textDecoration: 'none',
						}}
						to={`/requirements/${parent.id}`}
					>
						{parent.name || 'requirement'}{' '}
						{parent.version && `(${parent.version})`}
					</Link>
					<br />
				</>
			)}

			{childs.length > 0 && (
				<>
					<span>{t('followingRequirements')}: </span>
					<br />
					{childs.map((elem: any) => (
						<>
							{' '}
							<Link
								key={elem.id}
								style={{
									color: theme.palette.themePrimary,
									textDecoration: 'none',
								}}
								to={`/requirements/${elem.id}`}
							>
								{elem.name || 'requirement'}{' '}
								{elem.version
									? `(${elem.version})`
									: `(${t(`common/enums:RequirementStatus.${elem.status}`)})`}
							</Link>
							<br />
						</>
					))}
				</>
			)}
		</>
	);
};

export const detailsSectionMargin = 20;

export const DetailsSection: React.FC<DetailsSectionProps> = ({
	title,
	initialFieldRows,
	viewMoreFieldRows,
	auditLogData,
	titleWithAudit,
}) => {
	const {t} = useTranslation('components/detailssection');
	const theme = useTheme();
	const classnames = getClassnames(theme);
	const [viewMore, setViewMore] = React.useState<boolean>(false);
	const handleViewBtnClick = () => setViewMore(!viewMore);

	const downIcon: IIconProps = {iconName: 'ChevronDown'};
	const upIcon: IIconProps = {iconName: 'ChevronUp'};

	// eslint-disable-next-line complexity
	const renderField = (field: DetailsField, tooltipContent?: string) => {
		const {
			type,
			data,
			label,
			anonymous,
			audit,
			auditFieldName,
			disclaimer,
			enumProps,
			render,
			isLoading,
		} = field;

		if (tooltipContent) {
			return (
				<TooltipHost
					content={parseTooltipNewlines(tooltipContent)}
					directionalHint={DirectionalHint.topLeftEdge}
				>
					{renderField(field)}
				</TooltipHost>
			);
		}

		if (render) return render({...field, auditLogData});

		// Show text element history even if the value is null
		if (data === null || data === undefined) {
			return <Label>{label}</Label>;
		}

		switch (type) {
			case 'text':
				return (
					<TextField
						label={label}
						text={data}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			case 'boolean':
				return (
					<BooleanField
						label={label}
						data={data}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			case 'richtext':
				return (
					<RichtextField
						label={label}
						text={data}
						t={t}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			case 'history':
				return (
					<HistoryField
						label={label}
						historyEntries={data}
						t={t}
						isLoading={isLoading}
					/>
				);
			case 'array':
				return (
					<ArrayField
						label={label}
						data={data}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			case 'stringarray':
				return (
					<StringArrayField
						label={label}
						data={data}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			case 'date':
				return (
					<DateField
						label={label}
						data={data}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
						disclaimer={disclaimer}
					/>
				);
			case 'phase':
				return <PhaseField label={label} data={data} />;
			case 'personas':
				return (
					<PersonasField
						label={label}
						data={data}
						anonymous={anonymous}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			case 'attachments':
				return <AttachmentsField attachments={data} label={label} />;
			case 'references':
				return <ReferencesField label={label} data={data} />;
			case 'requirementRef':
				return <RequirementReferenceField label={label} data={data} />;
			case 'enumDropdown':
				if (enumProps === undefined) {
					throw new Error(`EnumDropDown Props are unndefined`);
				}

				return (
					<EnumDropdownField
						label={label}
						data={data}
						enumProps={enumProps}
						audit={audit}
						auditLogData={auditLogData}
						auditFieldName={auditFieldName}
					/>
				);
			default:
				return <></>;
		}
	};

	const containerStyles: IStackStyles = {
		root: {
			margin: detailsSectionMargin,
			background: theme.palette.white,
		},
	};

	return (
		<Stack styles={containerStyles}>
			<div className={classnames.title}>
				<Stack horizontal>
					<Label className={classnames.name}>{title}</Label>
					{titleWithAudit && (
						<ChangeHistory
							auditFieldName={'name'}
							data={auditLogData || []}
							renderOldValue={true}
						/>
					)}
				</Stack>
			</div>
			<div>
				{initialFieldRows.map((row, i) => (
					<div key={i}>
						<Stack
							horizontal
							tokens={{
								childrenGap: 50,
							}}
						>
							{row.map(field => (
								<div className={classnames.singleDetail} key={field.label}>
									{renderField(field, field.tooltipContent)}
								</div>
							))}
						</Stack>
						<Separator />
					</div>
				))}
			</div>
			{viewMoreFieldRows && (
				<>
					{viewMore && (
						<div>
							{viewMoreFieldRows.map((row, i) => (
								<div key={i}>
									<Stack
										horizontal
										tokens={{
											childrenGap: 50,
										}}
									>
										{row.map((field, j) => (
											<div key={j}>{renderField(field)}</div>
										))}
									</Stack>
									<Separator />
								</div>
							))}
							{}
						</div>
					)}
					<CommandButton
						text={viewMore ? t('ViewLessButton') : t('ViewMoreButton')}
						iconProps={viewMore ? upIcon : downIcon}
						onClick={handleViewBtnClick}
					/>
				</>
			)}
		</Stack>
	);
};

const getClassnames = (theme: Theme) =>
	mergeStyleSets({
		title: {
			background: theme.palette.neutralDark,
			height: 50,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '0 10px',
			marginBottom: 5,
		},
		name: {
			fontsize: 20,
			color: theme.palette.white,
		},
		link: {
			color: theme.palette.themePrimary,
			textDecoration: 'none',
		},
		singleDetail: {
			width: '50%',
		},
	});
