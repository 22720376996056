import {
	DefaultButton,
	IScrollablePaneStyles,
	Panel,
	PanelType,
	ScrollablePane,
	Separator,
	Stack,
	Text,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import {
	ControlledDatePicker,
	ControlledTagPicker,
	ControlledTextField,
	PhasePicker,
} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	GetParagraphsFormDataQuery,
	useGetParagraphsFormDataQuery,
} from 'features/RegulatoryDocuments/hooks/useGetParagraphsFormData.generated';
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {useForm} from 'react-hook-form';
import {
	RegulatoryDocumentParagraph as RegDocParagraph,
	UpdateParagraphAttachmentsInput,
	UserRole,
	WorkflowStatus,
} from 'types';
import {useParagraphsContext} from '../ParagraphsContext';
import {
	GetRegulatoryDocumentDetailsDocument,
	GetVexRegulatoryDocumentParagraphsQuery,
} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {fileUploadDefaultValue, useCommand, useFileUpload} from 'hooks';
import {RegDocAuditLogsInfo} from 'features/RegulatoryDocuments/RegDocDetailsPage/useGetRegDocAuditLogs';
import {
	UpdateParagraphAttachmentsMutation,
	useUpdateParagraphAttachmentsMutation,
} from 'features/RegulatoryDocuments/hooks/useUpdateParagraphAttachments.generated';
import {useUserContext} from 'authentication/UserContext';
import {renderParagraph} from 'components/EntityList/ColumnRenderers';
import {ControlledRichtextEditor} from 'components/hookForms/ControlledRichtextEditor';
import {v4 as uuidv4} from 'uuid';
import {ConfirmDeleteDialog, useDialogState} from 'components/Dialogs';
import {
	useClearFieldForAllParagraphsMutation,
	useClearFieldForSelectedParagraphsMutation,
} from 'features/RegulatoryDocuments/hooks/clearFieldRegulatoryDocumentParagraphBatch.generated';
import {ActionTagProps} from '../ActionTags/ActionTag';
import {ActionButton} from 'components';
import _ from 'lodash';
import {
	LabelWithTooltip,
	ProviderThatEnablesGettingTooltipsFromContext,
	RegDocParagraphTooltipTranslationProvider,
} from 'features/localizedTooltips';
import {formatDateTime} from 'i18n/localeDateFormat';
import {
	ParagraphFormFields,
	ParagraphsCopy,
	SelectableItemsByType,
	SetParagraphsCopy,
} from './EditParagraphsForm.types';
import {useEditParagraphsFormTranslations} from './editParagraphsFormTranslationHooks';
import {
	FieldsToUseEditParagraphsFormSubmitter,
	useEditParagraphsFormSubmitter,
} from './editParagraphsForm.submissionHook';
import {RegulatoryDetailsPageFormMode} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {DocOfRegDocDetailsPageQuery} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {ApolloClient, useApolloClient} from '@apollo/client';
import {MutationKeyOptimisticDataServiceFields} from './optimisticData/updateRegDocParagraphMutations/MutationKeyOptimisticData/MutationKeyOptimisticData.service';
import {
	AttachmentsMutationKeyOptimisticData,
	MutationKeyOptimisticDataService,
} from './optimisticData/updateParagraphAttachmentsMutation/MutationKeyOptimisticData.service';
import {useGetAttachmentCategoriesQuery} from 'components/hookForms/hooks/getAttachmentCategories.generated';
import {
	EditParagraphsFormSubmissionStatusContext,
	EditParagraphsFormSubmissionStatusInfo,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/EditParagraphsFormSubmissionStatusProvider';
import {
	EditParagraphsFormDefaultValues,
	initialRegulatoryDocumentParagraph,
} from './editParagraphsForm.constants';
import {couldEditRegulationWithModifiedVersions} from 'features/RegulatoryDocuments/regulatoryUtils';
import {ConfirmModifiedVersionCreation} from './ConfirmModifiedVersionCreation';
import {useCloneRegulatoryDocumentMutation} from 'features/RegulatoryDocuments/hooks/useCloneRegulatoryDocument.generated';
import {useNavigate} from 'react-router-dom';

type ParagraphKeysWithInitialFields = keyof EditParagraphsFormDefaultValues;

type FieldsFromAuditLogsInfo = Pick<
	RegDocAuditLogsInfo,
	'refetchAuditLogsAndUpdateIsLoading'
>;

type FieldsFromSubmissionHook = Pick<
	FieldsToUseEditParagraphsFormSubmitter,
	'setIsFormRefetchingRegDoc' | 'regDoc' | 'setAreAuditLogsLoading'
>;

interface Props extends FieldsFromAuditLogsInfo, FieldsFromSubmissionHook {
	vexItems: GetVexRegulatoryDocumentParagraphsQuery | undefined;
}

export const EditParagraphsForm = ({
	refetchAuditLogsAndUpdateIsLoading,
	setAreAuditLogsLoading,
	setIsFormRefetchingRegDoc,
	regDoc,
}: Props) => {
	const {t, i18n} = useEditParagraphsFormTranslations();
	const navigate = useNavigate();

	const [
		deleteMetadataDialogState,
		showDeleteMetadataDialog,
		hideDeleteMetadataDialog,
	] = useDialogState();

	const [metadataToPossiblyDelete, setMetadataToPossiblyDelete] =
		React.useState<keyof ParagraphFormFields | null>(null);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [showConfirmationDialogDelete, setShowConfirmationDialogDelete] =
		useState(false);
	const status = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;

	/**
	 * * Mutations
	 */
	const [updateParagraphAttachments] = useUpdateParagraphAttachmentsMutation();
	const [clearFieldForSelectedParagraphsMutation] =
		useClearFieldForSelectedParagraphsMutation();

	const [clearFieldForAllParagraphsMutation] =
		useClearFieldForAllParagraphsMutation();
	const [cloneRegulatoryDocumentMutation] =
		useCloneRegulatoryDocumentMutation();

	/**
	 * * Selected paragraphs
	 */
	const {selectedParagraphs, setSelectedParagraphs} = useParagraphsContext();

	type SelectedParagraphsState = [ParagraphsCopy, SetParagraphsCopy];
	const [
		selectedParagraphsCopy,
		setSelectedParagraphsCopy,
	]: SelectedParagraphsState = useState<ParagraphsCopy>([]);

	/**
	 * We must copy the paragraphs because we will modify them whenever we delete
	 * a field's metadata. We want to avoid changing @see selectedParagraphs
	 * because that would cause the form to reset, so we create a copy.
	 */
	const copySelectedParagraphsAndSetCopy = (): void => {
		const paragraphsCopy: ParagraphsCopy = _.cloneDeep(selectedParagraphs);
		setSelectedParagraphsCopy(paragraphsCopy);
	};

	useEffect(copySelectedParagraphsAndSetCopy, [selectedParagraphs]);

	/**
	 * * Cleared fields
	 */
	type ClearedFields = MutationKeyOptimisticDataServiceFields['clearedFields'];

	type SetClearedFields = Dispatch<SetStateAction<ClearedFields>>;

	type ClearedFieldsState = [ClearedFields, SetClearedFields];

	const [clearedFields, setClearedFields]: ClearedFieldsState =
		useState<ClearedFields>([]);

	const addClearedField = useCallback(
		(field: keyof ParagraphFormFields): void => {
			setClearedFields([...clearedFields, field]);
		},
		[clearedFields],
	);

	/**
	 * * Other hooks
	 */
	const apolloClient: ApolloClient<unknown> = useApolloClient();
	const {selectedRegulatoryDocument} = useRegulatoryDocumentsContext();
	const {
		isVko,
		isVkoExternal,
		isVex,
		isAdmin,
		isReader,
		username,
		encodedUserId,
	} = useUserContext();

	const {setSubmissionStarted, setSubmissionFinished} = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;

	const {
		handleSubmit,
		control,
		reset: resetFields,
		setValue,

		trigger,
	} = useForm<ParagraphFormFields>({
		reValidateMode: 'onSubmit',
		mode: 'all',
		defaultValues: initialRegulatoryDocumentParagraph,
	});

	const [mode, setMode] = React.useState(RegulatoryDetailsPageFormMode.None);
	const [everCleared, setEverCleared] = React.useState<boolean>(false);

	const {attachments, FileUploadComponent, setAttachments} = useFileUpload(
		selectedParagraphs.length > 1 ? undefined : selectedParagraphs[0],
		{
			enableVkoOnlyOption: true,
		},
	);

	const editRegulationForbidden = React.useMemo(() => {
		const isReapprovingVko =
			selectedRegulatoryDocument?.workflow?.reapprovingVKO?.id ===
			encodedUserId;
		const couldEdit = couldEditRegulationWithModifiedVersions(
			selectedRegulatoryDocument,
			encodedUserId,
		);
		return !isReapprovingVko && couldEdit;
	}, [selectedParagraphs, selectedRegulatoryDocument]);

	const hideMetadataEditForm = React.useMemo(() => {
		const status = selectedRegulatoryDocument?.workflow?.status;
		if (!status) {
			return false;
		}

		const clearingVko = selectedRegulatoryDocument.workflow?.clearingVKO[0];
		const isClearingVko = clearingVko?.userPrincipalName === username;
		const isVKoAllowed =
			[
				WorkflowStatus.QualityControlInternal,
				WorkflowStatus.QualityControlExternal,
			].includes(status) &&
			(isVko || isVkoExternal);

		const isClearing = status === WorkflowStatus.Clearing && !isClearingVko;

		const isHidden = isVKoAllowed || isClearing;
		return isHidden;
	}, [selectedRegulatoryDocument, username, isVko, isVkoExternal, isAdmin]);

	const handleEdit = () => {
		setMode(RegulatoryDetailsPageFormMode.Edit);
	};

	const disableMetadataEditForm = React.useMemo(() => {
		return (
			status.isSubmitting ||
			(editRegulationForbidden && !isVex) ||
			(selectedRegulatoryDocument?.workflow?.status !==
				WorkflowStatus.Finalized &&
				isReader) ||
			selectedParagraphs.length === 0
		);
	}, [
		editRegulationForbidden,
		isVex,
		isReader,
		selectedParagraphs,
		status?.isSubmitting,
		selectedRegulatoryDocument?.workflow?.status,
	]);

	const editMetadataTooltip = React.useMemo(() => {
		if (status?.isSubmitting === true)
			return t('DisabledEditMetadataSubmissionInProgress');
		if (editRegulationForbidden) return t('DisabledEditMetadataModifiedExists');
		return '';
	}, [editRegulationForbidden, status?.isSubmitting]);

	useCommand(
		{
			key: 'edit',
			text: t('Edit'),
			priority: 3,
			iconProps: {
				iconName: 'Edit',
			},
			title: editMetadataTooltip,
			disabled: disableMetadataEditForm,
			hidden: hideMetadataEditForm,
			onClick: handleEdit,
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.VkoExternal,
				UserRole.Vex,
			],
		},
		[mode, selectedParagraphs.length, hideMetadataEditForm],
	);

	const getDefaultFieldsAndReset = (): void => {
		resetFields(
			selectedParagraphs.length === 1
				? {
						...(selectedParagraphs[0] as ParagraphFormFields),
				  }
				: initialRegulatoryDocumentParagraph,
		);
	};

	const reset = (): void => {
		if (hideMetadataEditForm) return;

		getDefaultFieldsAndReset();
		/**
		 * We don't need to store the cleared fields for multiple submissions
		 * because the paragraphs' data will be updated.
		 */
		setClearedFields([]);
		/**
		 * We reset this so we can determine whether or not the attachments have
		 * been modified. This allows us to generate the optimistic data for
		 * attachments.
		 */
		setAttachments(fileUploadDefaultValue);
	};

	React.useEffect(reset, [mode, hideMetadataEditForm]);

	/**
	 * * Post-save functionality
	 */
	const {
		data: attachmentCategoriesData,
		loading: isLoadingAttachmentCategories,
	} = useGetAttachmentCategoriesQuery();

	interface AttachmentsInput
		extends Omit<UpdateParagraphAttachmentsInput, 'attachments'> {
		attachments: NonNullable<UpdateParagraphAttachmentsInput['attachments']>;
	}

	interface FieldsToCreateAttachmentsOptimisticData {
		regDoc: DocOfRegDocDetailsPageQuery;
		input: AttachmentsInput;
	}

	const createAttachmentsMutationKeyOptimisticData = useCallback(
		({
			regDoc,
			input,
		}: FieldsToCreateAttachmentsOptimisticData): AttachmentsMutationKeyOptimisticData => {
			return MutationKeyOptimisticDataService.createMutationKeyOptimisticData({
				...input,
				/**
				 * The paragraphs will always exist because we wouldn't be able to open
				 * the form unless they did.
				 */
				paragraphs: regDoc.paragraphs,
				/**
				 * This will always be defined because we wait for it in the loading
				 * screen when there we are editing a single paragraph.
				 */
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				attachmentCategories: attachmentCategoriesData!.attachmentCategories,
				idsToUpdate: input.paragraphIds,
				regDocId: input.regulatoryDocumentId,
				selectedParagraphs,
			});
		},
		[selectedParagraphs, attachmentCategoriesData],
	);

	const createAttachmentsOptimisticData = useCallback(
		(
			fields: FieldsToCreateAttachmentsOptimisticData,
		): UpdateParagraphAttachmentsMutation => {
			return {
				updateParagraphAttachments:
					createAttachmentsMutationKeyOptimisticData(fields),
			};
		},
		[createAttachmentsMutationKeyOptimisticData],
	);

	const onParagraphsSaved = React.useCallback(async () => {
		if (selectedParagraphs.length > 0 && regDoc?.id && attachments) {
			const input: AttachmentsInput = {
				regulatoryDocumentId: regDoc.id,
				attachments: attachments.map(f => ({
					attachmentId: f.file.name,
					file: f.file,
					categoryId: f.attachmentCategoryId,
					isVkoOnly: f.isVkoOnly,
				})),
				paragraphIds: selectedParagraphs.map(p => p.id),
			};

			await updateParagraphAttachments({
				variables: {input},
				optimisticResponse: createAttachmentsOptimisticData({
					regDoc,
					input,
				}),
			});
		}

		/**
		 * We must refetch the audit logs because the reg doc's "modelYear" can
		 * change if we update a paragraph. Since the reg doc's history can
		 * change, we must refetch the audit logs.
		 */

		await refetchAuditLogsAndUpdateIsLoading();
	}, [
		attachments,
		selectedParagraphs,
		regDoc,
		createAttachmentsOptimisticData,
		refetchAuditLogsAndUpdateIsLoading,
	]);

	const clearFieldRegulatoryDocumentParagraphsForFinalVersion =
		React.useCallback(
			async (fieldName: ParagraphKeysWithInitialFields) => {
				setSubmissionStarted();
				addClearedField(fieldName);

				if (!regDoc) return;
				const cloneResult = await cloneRegulatoryDocumentMutation({
					variables: {
						input: {
							originalRegulatoryDocumentId: regDoc.id,
							workflowStatus: WorkflowStatus.Modified,
						},
					},
				});

				const clonedRegDoc =
					cloneResult?.data?.cloneRegulatoryDocument?.regulatoryDocument;

				if (!clonedRegDoc) {
					return;
				}

				if (selectedParagraphs.length === regDoc?.paragraphs.length) {
					await clearFieldForAllParagraphsMutation({
						variables: {
							input: {
								fieldName,
								regulatoryDocumentId: clonedRegDoc?.id ?? '',
							},
						},
					}).catch((e: Error) => {
						console.error(
							'[EditParagraphForm/clearFieldRegulatoryDocumentParagraphsForFinalVersion]',
							e,
						);
					});
				} else {
					const positions: number[] = (regDoc?.paragraphs ?? []).reduce(
						(acc, elem, index) => {
							if (selectedParagraphs.map(sp => sp.id).includes(elem.id)) {
								acc.push(index);
							}
							return acc;
						},
						[] as number[],
					);

					const paragraphIds = clonedRegDoc.paragraphs.map(p => p.id);

					const paragraphsIdsInClonedVersion = positions.map(
						(elem: number) => paragraphIds[elem],
					);

					const cloneId = clonedRegDoc.id;

					await clearFieldForSelectedParagraphsMutation({
						variables: {
							input: {
								fieldName,
								paragraphIds: paragraphsIdsInClonedVersion ?? [0],
								regulatoryDocumentId: cloneId ?? '',
							},
						},
					}).catch((e: Error) => {
						console.error(
							'[EditParagraphForm/clearFieldRegulatoryDocumentParagraphsForFinalVersion]',
							e,
						);
					});
				}
				setEverCleared(true);
				setSubmissionFinished();
				navigate(`/regulations/${regDoc?.regulation?.id || ''}`);
			},
			[selectedParagraphs, selectedRegulatoryDocument, addClearedField],
		);

	/**
	 * * Other
	 */

	const clearFieldRegulatoryDocumentParagraphs = React.useCallback(
		async (fieldName: ParagraphKeysWithInitialFields) => {
			setSubmissionStarted();
			addClearedField(fieldName);

			const clearFunc =
				selectedParagraphs.length === regDoc?.paragraphs.length
					? () =>
							clearFieldForAllParagraphsMutation({
								variables: {
									input: {
										fieldName,
										regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
									},
								},
							})
					: () =>
							clearFieldForSelectedParagraphsMutation({
								variables: {
									input: {
										fieldName,
										paragraphIds: selectedParagraphs.map(sp => sp.id) ?? [0],
										regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
									},
								},
							});

			await clearFunc()
				.then(() => setEverCleared(true))
				.catch((e: Error) => {
					console.error(
						'[EditParagraphForm/clearFieldRegulatoryDocumentParagraphs]',
						e,
					);
				});
			setSubmissionFinished();
		},
		[selectedParagraphs, selectedRegulatoryDocument, addClearedField],
	);

	const handleSaveClick = useEditParagraphsFormSubmitter({
		handleSubmit,
		setMode,
		setSelectedParagraphsCopy,
		onParagraphsSaved,
		clearedFields,
		setIsFormRefetchingRegDoc,
		regDoc,
		setAreAuditLogsLoading,
		control,
		trigger,
		isVex,
	});

	const onRenderFooterContent: any = () => (
		<form className={classNames.form}>
			<ActionButton
				onClick={async () => {
					if (regDoc?.workflow?.status === WorkflowStatus.Finalized && !isVex) {
						setShowConfirmationDialog(true);
					} else {
						handleSaveClick();
					}
				}}
				styles={buttonStyles}
			>
				{t('UpdateButton')}
			</ActionButton>

			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	const refetchRegDoc = async (): Promise<void> => {
		await apolloClient.refetchQueries({
			include: [GetRegulatoryDocumentDetailsDocument],
		});
	};

	const refetchRegDocIfFieldsCleared = useCallback(async (): Promise<void> => {
		if (!clearedFields.length) return;
		/**
		 * When the user clears a field, the mutation doesn't refetch the data, so
		 * we need to refetch it here. We cannot have the mutation refetch the data
		 * or return the new data because that might cause the app to reset the form
		 * while the user is using it.
		 */
		await refetchRegDoc();
	}, [clearedFields]);

	const handleCancelClick = React.useCallback(async () => {
		setMode(RegulatoryDetailsPageFormMode.None);

		setSubmissionStarted();
		await refetchRegDocIfFieldsCleared();
		setSubmissionFinished();

		if (everCleared) {
			await onParagraphsSaved().catch((e: Error) =>
				console.error('[EditParagraphForm/handleCancelClick]', e),
			);
		}
	}, [everCleared, refetchRegDocIfFieldsCleared]);

	const {data, loading: isLoadingFormData} = useGetParagraphsFormDataQuery();
	const {
		driveVariantData,
		vehicleCategorieData,
		categoriesData,
		keywordData,
		tagData,
	} = React.useMemo(() => mapFormEdgeNodes(data), [data]);
	const theme = useTheme();

	const classNames = mergeStyleSets({
		arrayWrapper: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		arrayItem: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
		},
		form: {
			display: 'flex',
		},
	});

	const handleDeleteMetadata = React.useCallback(
		(metadata: keyof ParagraphFormFields) => {
			setMetadataToPossiblyDelete(metadata);

			if (regDoc?.workflow?.status === WorkflowStatus.Finalized && !isVex) {
				setShowConfirmationDialogDelete(true);
			} else {
				showDeleteMetadataDialog();
			}
		},
		[showDeleteMetadataDialog],
	);

	const getParagraphWithInitialFormValue = (
		key: ParagraphKeysWithInitialFields,
	) => {
		return (paragraph: RegDocParagraph): RegDocParagraph => {
			const value: RegDocParagraph[ParagraphKeysWithInitialFields] =
				initialRegulatoryDocumentParagraph[key];
			return {...paragraph, [key]: value};
		};
	};

	/**
	 * So that the this component's paragraphs list shows the correct value when
	 * we clear the field.
	 */
	const setParagraphsCopyWithInitialFormValue = (
		key: ParagraphKeysWithInitialFields,
	): void => {
		const paragraphs: ParagraphsCopy = selectedParagraphsCopy.map(
			getParagraphWithInitialFormValue(key),
		);
		setSelectedParagraphsCopy(paragraphs);
	};

	const setFormFieldValueToInitialValue = (
		key: ParagraphKeysWithInitialFields,
	): void => {
		const initialFormValue: ParagraphFormFields[ParagraphKeysWithInitialFields] =
			initialRegulatoryDocumentParagraph[key];
		setValue(key, initialFormValue);
	};

	const handleDeleteMetadataConfirmed = React.useCallback(async () => {
		const key = metadataToPossiblyDelete as ParagraphKeysWithInitialFields;
		setFormFieldValueToInitialValue(key);
		await clearFieldRegulatoryDocumentParagraphs(key)
			.catch((e: any) =>
				console.error('[EditParagraphForm/handleDeleteMetadataConfirmed]', e),
			)
			.finally(() => {
				hideDeleteMetadataDialog();
				setMode(RegulatoryDetailsPageFormMode.None);
			});
		setParagraphsCopyWithInitialFormValue(key);
	}, [metadataToPossiblyDelete, selectedParagraphsCopy]);

	const handleDeleteMetadataConfirmedForFinalVersion =
		React.useCallback(async () => {
			const key = metadataToPossiblyDelete as ParagraphKeysWithInitialFields;
			setFormFieldValueToInitialValue(key);
			await clearFieldRegulatoryDocumentParagraphsForFinalVersion(key)
				.catch((e: any) =>
					console.error(
						'[EditParagraphForm/handleDeleteMetadataConfirmedForFinalVersion]',
						e,
					),
				)
				.finally(() => {
					setShowConfirmationDialogDelete(false);
					setMode(RegulatoryDetailsPageFormMode.None);
				});
			setParagraphsCopyWithInitialFormValue(key);
		}, [metadataToPossiblyDelete, selectedParagraphsCopy]);

	const createDeletionTag = React.useCallback(
		(key: keyof ParagraphFormFields): ActionTagProps[] => {
			if (!isVko && !isAdmin) {
				return [];
			}

			return [
				{
					iconName: 'Cancel',
					title: t('DeleteMetadataButtonLabel'),
					onClick: () => handleDeleteMetadata(key),
				},
			];
		},
		[isVko, isAdmin],
	);

	const getIfIsLoading = (): boolean => {
		/**
		 * We wait for attachment categories when editing a single paragraph in case
		 * we need to create optimistic attachments, which requires the attachment categories.
		 */
		if (selectedParagraphs.length === 1) {
			return isLoadingFormData || isLoadingAttachmentCategories;
		}

		return isLoadingFormData;
	};

	return (
		<Panel
			isLightDismiss
			type={PanelType.extraLarge}
			isFooterAtBottom={true}
			onDismiss={handleCancelClick}
			isOpen={mode === RegulatoryDetailsPageFormMode.Edit}
			closeButtonAriaLabel='Close'
			headerText={t('EditMetaDataPanelHeader')}
			onRenderFooterContent={onRenderFooterContent}
		>
			<LoadWrapper loading={getIfIsLoading()}>
				<Stack
					horizontal
					styles={{root: {height: '100%', position: 'relative'}}}
				>
					<ScrollablePane styles={scrollablePaneStyles}>
						<Stack tokens={{childrenGap: 8}} styles={{root: {marginTop: 4}}}>
							{selectedParagraphsCopy.map(p => (
								<div key={`${p.id}-paragraph`}>
									<div key={p.id}>{renderParagraph(p, t)}</div>
									{selectedParagraphsCopy.length > 1 && (
										<div>
											{/* Date New Types */}
											{Boolean(p.dateNewTypes) && (
												<div
													key={`${p.id}-new-types`}
													className={classNames.arrayWrapper}
												>
													<div className={classNames.arrayItem}>
														{formatDateTime(new Date(p.dateNewTypes), i18n)}
													</div>
												</div>
											)}
											{/* Date New Regsitration */}
											{Boolean(p.dateNewRegistration) && (
												<div
													key={`${p.id}-new-registration`}
													className={classNames.arrayWrapper}
												>
													<div className={classNames.arrayItem}>
														{formatDateTime(
															new Date(p.dateNewRegistration),
															i18n,
														)}
													</div>
												</div>
											)}
											{/* Keywords */}
											<div
												key={`${p.id}-keywords`}
												className={classNames.arrayWrapper}
											>
												{p.keywords.map(k => (
													<div key={uuidv4()} className={classNames.arrayItem}>
														{k.name}
													</div>
												))}
											</div>
											{/* Drive variants */}
											<div
												key={`${p.id}-driveVariants`}
												className={classNames.arrayWrapper}
											>
												{p.driveVariants.map(dv => (
													<div key={uuidv4()} className={classNames.arrayItem}>
														{dv.name}
													</div>
												))}
											</div>
											{/* Vehicle Categories */}
											<div
												key={`${p.id}-vehicleCategories`}
												className={classNames.arrayWrapper}
											>
												{p.vehicleCategories.map(vc => (
													<div key={uuidv4()} className={classNames.arrayItem}>
														{vc.name}
													</div>
												))}
											</div>
											{/* Categories */}
											<div
												key={`${p.id}-categories`}
												className={classNames.arrayWrapper}
											>
												{p.categories.map(category => (
													<div
														key={category.id}
														className={classNames.arrayItem}
													>
														{category.name}
													</div>
												))}
											</div>
											{/* Tags */}
											<div
												key={`${p.id}-tags`}
												className={classNames.arrayWrapper}
											>
												{p.tags.map(t => (
													<div key={uuidv4()} className={classNames.arrayItem}>
														{t.name}
													</div>
												))}
											</div>
											{/* Phase In */}
											<div
												key={`${p.id}-phase-in`}
												className={classNames.arrayWrapper}
											>
												{p.phaseIn?.map(phaseIn => (
													<div
														key={phaseIn.status}
														className={classNames.arrayItem}
													>
														{formatDateTime(new Date(phaseIn.date), i18n)} (
														{phaseIn.status})
													</div>
												))}
											</div>
											{/* Phase Out */}
											<div
												key={`${p.id}-phase-out`}
												className={classNames.arrayWrapper}
											>
												{p.phaseOut?.map(phaseOut => (
													<div
														key={phaseOut.status}
														className={classNames.arrayItem}
													>
														{formatDateTime(new Date(phaseOut.date), i18n)} (
														{phaseOut.status})
													</div>
												))}
											</div>
										</div>
									)}
								</div>
							))}
						</Stack>
					</ScrollablePane>
					<Separator vertical />
					<Stack horizontal={false} tokens={tokens} styles={stackStyles}>
						<ScrollablePane styles={scrollablePaneStyles}>
							<RegDocParagraphTooltipTranslationProvider>
								<ProviderThatEnablesGettingTooltipsFromContext>
									{selectedParagraphs.length > 1 && (
										<Text>
											Hinweis: Getroffene Auswahl wird zu den bereits
											vorhandenen Einträgen hinzugefügt
										</Text>
									)}
									<ControlledTagPicker
										name={'keywords'}
										label={t('KeywordTagPickerLabel')}
										control={control}
										selectableItems={keywordData}
										disabled={isVex}
										getKey={item => item.id}
										getName={item => item.name}
										actionTags={createDeletionTag('keywords')}
									/>
									<ControlledTagPicker
										name={'driveVariants'}
										label={t('DriveVariantTagPickerLabel')}
										control={control}
										selectableItems={driveVariantData}
										disabled={isVex}
										getKey={item => item.id}
										getName={item => item.name}
										actionTags={createDeletionTag('driveVariants')}
									/>
									<ControlledTagPicker
										name={'vehicleCategories'}
										label={t('VehicleCategoryTagPickerLabel')}
										control={control}
										selectableItems={vehicleCategorieData}
										disabled={isVex}
										getKey={item => item.id}
										getName={item => item.name}
										actionTags={createDeletionTag('vehicleCategories')}
									/>
									<ControlledTagPicker
										name={'categories'}
										label={t('CategoryTagPickerLabel')}
										control={control}
										selectableItems={categoriesData}
										disabled={isVex}
										getKey={item => item.id}
										getName={item => item.name}
										actionTags={createDeletionTag('categories')}
									/>
									<ControlledTagPicker
										name={'tags'}
										label={t('TagTagPickerLabel')}
										control={control}
										selectableItems={tagData}
										getKey={item => item.id}
										getName={item => item.name}
										actionTags={createDeletionTag('tags')}
									/>
									{selectedParagraphs.length > 1 && (
										<div>
											<Separator />
											<Text>
												Hinweis: Vorhandene Einträge werden mit der getroffenen
												Auswahl überschrieben
											</Text>
										</div>
									)}
									<Stack horizontal tokens={tokens}>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<ControlledTextField
												placeholder={`${t('ForExample')} ${new Date()
													.getFullYear()
													.toString()}`}
												control={control}
												disabled={isVex}
												name={'modelYear'}
												label={t('ModelYearSpinButton')}
												rules={{
													pattern: {
														value: /^[1-9][0-9]{3}$/,
														message: t('ErrorModelYear'),
													},
												}}
												actionTags={createDeletionTag('modelYear')}
											/>
										</Stack.Item>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<ControlledDatePicker
												control={control}
												name={'comprehensive'}
												label={t('ComprehensiveDatePicker')}
												disabled={isVex}
												actionTags={createDeletionTag('comprehensive')}
											/>
										</Stack.Item>
									</Stack>
									<Stack horizontal tokens={tokens}>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<ControlledDatePicker
												control={control}
												name={'dateNewTypes'}
												label={t('DateNewTypesDatePicker')}
												disabled={isVex}
												actionTags={createDeletionTag('dateNewTypes')}
											/>
										</Stack.Item>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<ControlledDatePicker
												control={control}
												name={'dateNewRegistration'}
												label={t('DateNewRegistrationDatePicker')}
												disabled={isVex}
												actionTags={createDeletionTag('dateNewRegistration')}
											/>
										</Stack.Item>
									</Stack>
								</ProviderThatEnablesGettingTooltipsFromContext>
								<Separator />
								<PhasePicker
									control={control}
									label='Phase In'
									name={'phaseIn'}
									disabled={isVex}
									actionTags={createDeletionTag('phaseIn')}
								/>
								<Separator />
								<PhasePicker
									control={control}
									label='Phase Out'
									name={'phaseOut'}
									disabled={isVex}
									actionTags={createDeletionTag('phaseOut')}
								/>
								<Separator />
								<ProviderThatEnablesGettingTooltipsFromContext>
									<ControlledRichtextEditor
										label={t('SummaryFieldLabel')}
										control={control}
										name={'summary'}
										disabled={isVex}
										/* NOTE: temporarily commented since ControlledRichTextEditor isn't reactive to values
									actionTags={[
										{
											iconName: 'Cancel',
											onClick: () => handleDeleteMetadata('summary'),
										},
									]}
									*/
									/>
									{selectedParagraphs.length === 1 && !isVex && (
										<>
											<Separator />
											<LabelWithTooltip translationKey='attachments'>
												{t('AttachmentsFieldLabel')}
											</LabelWithTooltip>
											<FileUploadComponent />
										</>
									)}
								</ProviderThatEnablesGettingTooltipsFromContext>
							</RegDocParagraphTooltipTranslationProvider>
						</ScrollablePane>
						<ConfirmDeleteDialog
							{...deleteMetadataDialogState}
							onConfirm={handleDeleteMetadataConfirmed}
						/>
						<ConfirmModifiedVersionCreation
							showConfirmationDialog={showConfirmationDialog}
							setShowConfirmationDialog={setShowConfirmationDialog}
							handleSaveClick={handleSaveClick}
						/>
						<ConfirmModifiedVersionCreation
							showConfirmationDialog={showConfirmationDialogDelete}
							setShowConfirmationDialog={setShowConfirmationDialogDelete}
							handleSaveClick={handleDeleteMetadataConfirmedForFinalVersion}
						/>
					</Stack>
				</Stack>
			</LoadWrapper>
		</Panel>
	);
};

const mapFormEdgeNodes = (
	data?: GetParagraphsFormDataQuery,
): SelectableItemsByType => ({
	mainKeywordData: data?.keywords ?? [],
	vehicleCategorieData: data?.vehicleCategories ?? [],
	categoriesData: data?.categories ?? [],
	driveVariantData: data?.driveVariants ?? [],
	keywordData: data?.keywords ?? [],
	tagData: data?.tags ?? [],
});

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};

const stackStyles = {
	root: {width: '100%', height: '100%'},
};

const stackItemStyles = {
	root: {width: '100%'},
};

const tokens = {
	childrenGap: '5px',
};

const scrollablePaneStyles = {
	root: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
	contentContainer: {
		overflowX: 'hidden',
	},
} as Partial<IScrollablePaneStyles>;
