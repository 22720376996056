import {Control} from 'react-hook-form';
import {NotificationFormat, NotificationFrequency, Subscription} from 'types';
import {useSubscriptionsTranslation} from '../hooks/useSubscriptionsTranslation';
import {
	GetSubscriptionFormDataQuery,
	useGetSubscriptionFormDataQuery,
} from '../hooks/subscriptionsFormData.generated';
import React from 'react';
import {ProviderThatEnablesGettingTooltipsFromContext} from 'features/localizedTooltips';
import {
	ControlledTagPicker,
	ControlledTextField,
	ControlledToggle,
	EnumDropdown,
} from 'components/hookForms';
import {Separator, Stack} from '@fluentui/react';

type SubscriptionFormElementProps = {
	control: Control<Subscription>;
};

const SubscriptionFormElements = ({
	control,
}: SubscriptionFormElementProps): JSX.Element => {
	const {t} = useSubscriptionsTranslation();
	const {data} = useGetSubscriptionFormDataQuery();

	const {keywordData, marketData, pcmsClusterData} = React.useMemo(
		() => mapFormEdgeNodes(data),
		[data],
	);

	// TODO - rework this, if enum source changes this will break.
	// This is just temporary solution to keep the requested order.
	// Order should be done by graphql instead.
	const NotificationFrequencySortOrder: string[] = [
		'None',
		'Daily',
		'Weekly',
		'Monthly',
		'Quarterly',
	];

	const wrapWithStackItem = (
		field: JSX.Element,
		index: number,
	): JSX.Element => {
		return <Stack.Item key={index}>{field}</Stack.Item>;
	};

	const renderFormElements = (): JSX.Element[] => {
		let index = 0;

		const items: JSX.Element[] = [
			<ControlledTextField
				required={true}
				label={t('NameFieldLabel')}
				control={control}
				name={'name'}
				key={++index}
				rules={{required: t('NameFieldRequired')}}
			/>,
			<ControlledTagPicker
				label={t('MarketsTagPickerLabel')}
				control={control}
				name={'markets'}
				key={++index}
				selectableItems={marketData}
			/>,
			<ControlledTagPicker
				control={control}
				name={'keywords'}
				key={++index}
				label={t('KeywordsTagPickerLabel')}
				selectableItems={keywordData}
			/>,
			<ControlledToggle
				control={control}
				name={'optOnlyForMainKeywords'}
				label={t('OptOnlyForMainKeywords')}
				key={++index}
			/>,
			<ControlledTagPicker
				control={control}
				name={'standardPlusPcmsClusters'}
				key={++index}
				label={t('PcmsClustersTagPickerLabel')}
				selectableItems={pcmsClusterData}
			/>,
			<Separator key={++index} />,
			<EnumDropdown
				label={t('NotificationFrequencyLabel')}
				control={control}
				name={'notificationFrequency'}
				placeholder={t('NotificationFrequencyPlaceholder')}
				enumName={'SubscriptionNotificationFrequency'}
				enumType={NotificationFrequency}
				defaultValue={NotificationFrequency.None}
				sortOrder={NotificationFrequencySortOrder}
				key={++index}
			/>,
			<EnumDropdown
				label={t('NotificationFormatLabel')}
				control={control}
				name={'notificationFormat'}
				placeholder={t('NotificationFormatPlaceholder')}
				enumName={'SubscriptionNotificationFormat'}
				enumType={NotificationFormat}
				defaultValue={NotificationFormat.Email}
				exclude={[NotificationFormat.None]}
				key={++index}
			/>,
		];
		return items.map(wrapWithStackItem);
	};

	const formElements: JSX.Element[] = renderFormElements();

	return (
		<>
			<ProviderThatEnablesGettingTooltipsFromContext>
				{formElements}
			</ProviderThatEnablesGettingTooltipsFromContext>
		</>
	);
};

const mapFormEdgeNodes = (data?: GetSubscriptionFormDataQuery) => ({
	keywordData: data?.keywords ?? [],
	marketData: data?.markets ?? [],
	pcmsClusterData: data?.standardPlusPcmsClusters ?? [],
});
export default SubscriptionFormElements;
