export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
  DateTime: any;
  Long: any;
  URL: any;
  UUID: any;
  Upload: any;
};

export type ActiveTypeMarket = Entity & NamedEntity & Node & {
  __typename?: 'ActiveTypeMarket';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type ActiveTypeMarketSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AddCommentaryInput = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};

export type AddFaqInput = {
  answer: Scalars['String'];
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type AddOrUpdateTableConfigurationInput = {
  columns: Array<ColumnInput>;
  tableId: Scalars['String'];
};

export type AddOrUpdateTableConfigurationPayload = {
  __typename?: 'AddOrUpdateTableConfigurationPayload';
  user?: Maybe<User>;
};

export type AddRegulatoryDocumentParagraphCommentaryInput = {
  commentary: AddCommentaryInput;
  paragraphId: Scalars['ID'];
};

export type AddRegulatoryDocumentParagraphCommentaryPayload = {
  __typename?: 'AddRegulatoryDocumentParagraphCommentaryPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type AddRegulatoryDocumentParagraphFaqInput = {
  faqInput: AddFaqInput;
  paragraphId: Scalars['ID'];
};

export type AddRegulatoryDocumentParagraphFaqPayload = {
  __typename?: 'AddRegulatoryDocumentParagraphFaqPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type AggregatedFields = {
  __typename?: 'AggregatedFields';
  vehicleCategories: Array<VehicleCategory>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ApprovalWorkFlow = {
  __typename?: 'ApprovalWorkFlow';
  approverRefs: Array<User>;
  clearingTimestamp?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
};

export type ApprovalWorkFlowInput = {
  approverRefs: Array<EntityRefOfUserInput>;
  clearingTimestamp?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
};

export type AssignRegulatoryDocumentParagraphsRequirementsDocumentCompareInput = {
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
  requirementRefs: Array<Array<EntityRefOfRequirementInput>>;
};

export type AssignRegulatoryDocumentParagraphsRequirementsDocumentComparePayload = {
  __typename?: 'AssignRegulatoryDocumentParagraphsRequirementsDocumentComparePayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type AssignRegulatoryDocumentParagraphsRequirementsInput = {
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
  requirementRefs: Array<EntityRefOfRequirementInput>;
};

export type AssignRegulatoryDocumentParagraphsRequirementsPayload = {
  __typename?: 'AssignRegulatoryDocumentParagraphsRequirementsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type AssignRegulatoryDocumentParagraphsTagsInput = {
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
  tagRefs: Array<EntityRefOfTagInput>;
};

export type AssignRegulatoryDocumentParagraphsTagsPayload = {
  __typename?: 'AssignRegulatoryDocumentParagraphsTagsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type AssignRequirementsBatchManuallyInput = {
  addReason: Scalars['String'];
  requirementIds: Array<Scalars['ID']>;
  vehicleProjectIds: Array<Scalars['ID']>;
};

export type AssignRequirementsBatchManuallyPayload = {
  __typename?: 'AssignRequirementsBatchManuallyPayload';
  vehicleProject?: Maybe<Array<VehicleProject>>;
};

export type AssignRequirementsInput = {
  requirementIds: Array<Scalars['ID']>;
  vehicleProjectId: Scalars['ID'];
};

export type AssignRequirementsManuallyInput = {
  requirementIds: Array<Scalars['ID']>;
  vehicleProjectId: Scalars['ID'];
};

export type AssignRequirementsManuallyPayload = {
  __typename?: 'AssignRequirementsManuallyPayload';
  vehicleProject?: Maybe<VehicleProject>;
};

export type AssignRequirementsPayload = {
  __typename?: 'AssignRequirementsPayload';
  vehicleProject?: Maybe<VehicleProject>;
};

export type AssignVehicleProjectPermissionsInput = {
  modelSeries: Scalars['String'];
  users: Array<Scalars['ID']>;
};

export type AssignVehicleProjectPermissionsPayload = {
  __typename?: 'AssignVehicleProjectPermissionsPayload';
  user?: Maybe<Array<User>>;
};

export type AssignedRequirement = {
  __typename?: 'AssignedRequirement';
  addReason?: Maybe<Scalars['String']>;
  added: Scalars['DateTime'];
  addedManually: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  removeReason?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  requirement: Requirement;
};

export type AttachmentCategory = Entity & NamedEntity & Node & {
  __typename?: 'AttachmentCategory';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type AttachmentCategorySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AttachmentInput = {
  attachmentId: Scalars['String'];
  categoryId?: InputMaybe<Scalars['ID']>;
  contentType?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  isVkoOnly?: Scalars['Boolean'];
};

export type AttachmentRef = {
  __typename?: 'AttachmentRef';
  attachmentId: Scalars['String'];
  category?: Maybe<AttachmentCategory>;
  file: BlobRef;
  isVkoOnly?: Maybe<Scalars['Boolean']>;
};

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  change: AuditLogChange;
  changeSetId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  entity?: Maybe<Node>;
  entityId: Scalars['String'];
  entityNodeId: Scalars['String'];
  entityOperation: EntityOperation;
  entityType: Scalars['String'];
  id: Scalars['ID'];
};

export type AuditLogChange = {
  __typename?: 'AuditLogChange';
  key: Scalars['String'];
  nodeId?: Maybe<Scalars['String']>;
  oldNodeId?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['Any']>;
  path: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['Any']>;
  valueType?: Maybe<Scalars['String']>;
};

export type AuditLogChangeFilterInput = {
  and?: InputMaybe<Array<AuditLogChangeFilterInput>>;
  key?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AuditLogChangeFilterInput>>;
  path?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  valueType?: InputMaybe<StringOperationFilterInput>;
};

export type AuditLogChangeSortInput = {
  key?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  valueType?: InputMaybe<SortEnumType>;
};

export type AuditLogFilterInput = {
  and?: InputMaybe<Array<AuditLogFilterInput>>;
  change?: InputMaybe<AuditLogChangeFilterInput>;
  changeSetId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  entityId?: InputMaybe<StringOperationFilterInput>;
  entityOperation?: InputMaybe<EntityOperationOperationFilterInput>;
  entityType?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<AuditLogFilterInput>>;
};

export type AuditLogSortInput = {
  change?: InputMaybe<AuditLogChangeSortInput>;
  changeSetId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  entityOperation?: InputMaybe<SortEnumType>;
  entityType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AuditLogsConnection = {
  __typename?: 'AuditLogsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AuditLogsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AuditLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AuditLogsEdge = {
  __typename?: 'AuditLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AuditLog;
};

export type Baseline = Entity & NamedEntity & {
  __typename?: 'Baseline';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtUtcDays: Scalars['Long'];
  createdAtUtcSeconds: Scalars['Long'];
  createdBy: User;
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  requirements: Array<BaselineRequirement>;
  vehiclePorjectName: Scalars['String'];
};

export type BaselineChange = {
  __typename?: 'BaselineChange';
  changes: Array<BaselineCompareChangeElement>;
  compareStatus: Scalars['String'];
  requirement: Requirement;
};

export type BaselineCompareChangeElement = {
  __typename?: 'BaselineCompareChangeElement';
  field: Scalars['String'];
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
  type: BaselineCompareChangeElementType;
};

export enum BaselineCompareChangeElementType {
  BoolType = 'BOOL_TYPE',
  DateTimeType = 'DATE_TIME_TYPE',
  IntType = 'INT_TYPE',
  ListType = 'LIST_TYPE',
  StringType = 'STRING_TYPE'
}

export type BaselineCompareResult = {
  __typename?: 'BaselineCompareResult';
  changes: Array<BaselineChange>;
  id1: Scalars['String'];
  id2: Scalars['String'];
  name1: Scalars['String'];
  name2: Scalars['String'];
};

export type BaselineCreateResult = {
  __typename?: 'BaselineCreateResult';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BaselineRequirement = Entity & Node & {
  __typename?: 'BaselineRequirement';
  activeTypeMarketList: Array<ActiveTypeMarket>;
  attachmentCategoryList: Array<AttachmentCategory>;
  attachments?: Maybe<Array<AttachmentRef>>;
  bodyworkVariantList: Array<BodyworkVariant>;
  categoryList: Array<Category>;
  convoluteList: Array<Convolute>;
  countryList: Array<Country>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  documentSourceList: Array<DocumentSource>;
  driveVariantList: Array<DriveVariant>;
  engineVariantList: Array<EngineVariant>;
  gdprRuleList: Array<GdprRule>;
  gearboxVariantList: Array<GearboxVariant>;
  id: Scalars['ID'];
  keywordList: Array<Keyword>;
  marketList: Array<Market>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  referenceList: Array<Reference>;
  regulationClusterList: Array<RegulationCluster>;
  requirement: Requirement;
  standardPlusPcmsClusterList: Array<StandardPlusPcmsCluster>;
  systemLevelList: Array<SystemLevel>;
  tagList: Array<Tag>;
  userList: Array<User>;
  vehicleCategoryList: Array<VehicleCategory>;
  vexClusterList: Array<VexCluster>;
};

export type BaselineRequirementInput = {
  activeTypeMarketList: Array<CreateActiveTypeMarketInput>;
  attachmentCategoryList: Array<CreateAttachmentCategoryInput>;
  bodyworkVariantList: Array<CreateBodyworkVariantInput>;
  categoryList: Array<CreateCategoryInput>;
  convoluteList: Array<CreateConvoluteInput>;
  countryList: Array<CreateCountryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  documentSourceList: Array<CreateDocumentSourceInput>;
  driveVariantList: Array<CreateDriveVariantInput>;
  engineVariantList: Array<CreateEngineVariantInput>;
  gdprRuleList: Array<CreateGdprRuleInput>;
  gearboxVariantList: Array<CreateGearboxVariantInput>;
  id: Scalars['ID'];
  keywordList: Array<CreateKeywordInput>;
  marketList: Array<CreateMarketInput>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  referenceList: Array<CreateReferenceInput>;
  regulationClusterList: Array<CreateRegulationClusterInput>;
  requirement: RequirementInput;
  standardPlusPcmsClusterList: Array<CreateStandardPlusPcmsClusterInput>;
  systemLevelList: Array<CreateSystemLevelInput>;
  tagList: Array<CreateTagInput>;
  userList: Array<UserInput>;
  vehicleCategoryList: Array<CreateVehicleCategoryInput>;
  vexClusterList: Array<CreateVexClusterInput>;
};

export type BlobRef = {
  __typename?: 'BlobRef';
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  uri: Scalars['URL'];
};

export type BodyworkVariant = Entity & NamedEntity & Node & {
  __typename?: 'BodyworkVariant';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type BodyworkVariantSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type Category = Entity & NamedEntity & Node & {
  __typename?: 'Category';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type CategoryNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type CategorySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type CheckVersionNameRegulatoryDocumentInput = {
  regulationId: Scalars['ID'];
  regulatoryDocumentName: Scalars['String'];
};

export type CheckVersionNameRegulatoryDocumentPayload = {
  __typename?: 'CheckVersionNameRegulatoryDocumentPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type ClearFieldForAllParagraphsInput = {
  fieldName: Scalars['String'];
  regulatoryDocumentId: Scalars['ID'];
};

export type ClearFieldForAllParagraphsPayload = {
  __typename?: 'ClearFieldForAllParagraphsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type ClearFieldForSelectedParagraphsInput = {
  fieldName: Scalars['String'];
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
};

export type ClearFieldForSelectedParagraphsPayload = {
  __typename?: 'ClearFieldForSelectedParagraphsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type CloneAllParagraphsForRegulatoryDocumentInput = {
  distRegulatoryDocument: Scalars['ID'];
  sourceRegulatoryDocument: Scalars['ID'];
};

export type CloneAllParagraphsForRegulatoryDocumentPayload = {
  __typename?: 'CloneAllParagraphsForRegulatoryDocumentPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type CloneRegulatoryDocumentInput = {
  originalRegulatoryDocumentId: Scalars['ID'];
  reapprovingVKO?: InputMaybe<EntityRefOfUserInput>;
  workflowStatus: WorkflowStatus;
};

export type Column = {
  __typename?: 'Column';
  hidden: Scalars['Boolean'];
  key: Scalars['String'];
  width: Scalars['Int'];
};

export type ColumnInput = {
  hidden: Scalars['Boolean'];
  key: Scalars['String'];
  width: Scalars['Int'];
};

export type Commentary = Entity & {
  __typename?: 'Commentary';
  attachments?: Maybe<Array<AttachmentRef>>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
};

export type CommentaryFilterInput = {
  and?: InputMaybe<Array<CommentaryFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CommentaryFilterInput>>;
};

export type CommentaryInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ConcludeRegulatoryDocumentInput = {
  regulatoryDocumentId: Scalars['ID'];
};

export type ConcludeRegulatoryDocumentPayload = {
  __typename?: 'ConcludeRegulatoryDocumentPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type Convolute = Entity & NamedEntity & Node & {
  __typename?: 'Convolute';
  attachments?: Maybe<Array<AttachmentRef>>;
  convoluteType: ConvoluteType;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  gdprRule?: Maybe<GdprRule>;
  gdprRuleRef?: Maybe<EntityRefOfGdprRule>;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type ConvoluteSortInput = {
  convoluteType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  gdprRuleRef?: InputMaybe<EntityRefOfGdprRuleSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum ConvoluteType {
  Regulation = 'REGULATION',
  RegulatoryDocument = 'REGULATORY_DOCUMENT',
  Requirement = 'REQUIREMENT',
  VehicleProject = 'VEHICLE_PROJECT'
}

export type Country = Entity & NamedEntity & Node & {
  __typename?: 'Country';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type CountryNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type CountrySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type CreateActiveTypeMarketInput = {
  name: Scalars['String'];
};

export type CreateActiveTypeMarketPayload = {
  __typename?: 'CreateActiveTypeMarketPayload';
  activeTypeMarket?: Maybe<ActiveTypeMarket>;
};

export type CreateAttachmentCategoryInput = {
  name: Scalars['String'];
};

export type CreateAttachmentCategoryPayload = {
  __typename?: 'CreateAttachmentCategoryPayload';
  attachmentCategory?: Maybe<AttachmentCategory>;
};

export type CreateBaselineInput = {
  createdAtUtcDays: Scalars['Long'];
  createdAtUtcSeconds: Scalars['Long'];
  isCurrent: Scalars['Boolean'];
  name: Scalars['String'];
  requirements: Array<BaselineRequirementInput>;
  vehiclePorjectName: Scalars['String'];
};

export type CreateBaselinePayload = {
  __typename?: 'CreateBaselinePayload';
  baseline?: Maybe<Baseline>;
};

export type CreateBodyworkVariantInput = {
  name: Scalars['String'];
};

export type CreateBodyworkVariantPayload = {
  __typename?: 'CreateBodyworkVariantPayload';
  bodyworkVariant?: Maybe<BodyworkVariant>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
};

export type CreateConvoluteInput = {
  convoluteType: ConvoluteType;
  gdprRuleRef?: InputMaybe<EntityRefOfGdprRuleInput>;
  name: Scalars['String'];
};

export type CreateConvolutePayload = {
  __typename?: 'CreateConvolutePayload';
  convolute?: Maybe<Convolute>;
};

export type CreateCountryInput = {
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateCountryPayload = {
  __typename?: 'CreateCountryPayload';
  country?: Maybe<Country>;
};

export type CreateDocumentSourceInput = {
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateDocumentSourcePayload = {
  __typename?: 'CreateDocumentSourcePayload';
  documentSource?: Maybe<DocumentSource>;
};

export type CreateDriveVariantInput = {
  name: Scalars['String'];
};

export type CreateDriveVariantPayload = {
  __typename?: 'CreateDriveVariantPayload';
  driveVariant?: Maybe<DriveVariant>;
};

export type CreateEngineVariantInput = {
  name: Scalars['String'];
};

export type CreateEngineVariantPayload = {
  __typename?: 'CreateEngineVariantPayload';
  engineVariant?: Maybe<EngineVariant>;
};

export type CreateGdprRuleInput = {
  anonymize: Scalars['Boolean'];
  deletionPeriod: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateGdprRulePayload = {
  __typename?: 'CreateGdprRulePayload';
  gdprRule?: Maybe<GdprRule>;
};

export type CreateGearboxVariantInput = {
  name: Scalars['String'];
};

export type CreateGearboxVariantPayload = {
  __typename?: 'CreateGearboxVariantPayload';
  gearboxVariant?: Maybe<GearboxVariant>;
};

export type CreateKeywordInput = {
  approverRefs: Array<EntityRefOfUserInput>;
  name: Scalars['String'];
  regulationClusterRefs: Array<EntityRefOfRegulationClusterInput>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  vexClusterRefs: Array<EntityRefOfVexClusterInput>;
  vkoRefs: Array<EntityRefOfVkoInput>;
};

export type CreateKeywordPayload = {
  __typename?: 'CreateKeywordPayload';
  keyword?: Maybe<Keyword>;
};

export type CreateMarketInput = {
  countriesRef: Array<EntityRefOfCountryInput>;
  name: Scalars['String'];
  regulationNamingTemplates: Array<NamingTemplateInput>;
  regulatoryDocumentNamingTemplates: Array<NamingTemplateInput>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateMarketPayload = {
  __typename?: 'CreateMarketPayload';
  market?: Maybe<Market>;
};

export type CreatePogisDocumentInput = {
  associations?: InputMaybe<Array<Scalars['String']>>;
  dates: Array<PogisDateInput>;
  docStatus: Scalars['String'];
  editor: Scalars['String'];
  keywords: Array<Scalars['String']>;
  linkVersion?: InputMaybe<Scalars['String']>;
  markets: Array<Scalars['String']>;
  pogisId: Scalars['String'];
  regulationStatus: Scalars['String'];
  relatedDocuments?: InputMaybe<Array<Scalars['String']>>;
  shortInfo: Scalars['String'];
  standards?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateReferenceInput = {
  name: Scalars['String'];
  referenceTypes: Array<ReferenceType>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateReferencePayload = {
  __typename?: 'CreateReferencePayload';
  reference?: Maybe<Reference>;
};

export type CreateRegulationClusterInput = {
  name: Scalars['String'];
};

export type CreateRegulationClusterPayload = {
  __typename?: 'CreateRegulationClusterPayload';
  regulationCluster?: Maybe<RegulationCluster>;
};

export type CreateRegulationInput = {
  deletionPeriod: Scalars['Int'];
  mainKeywordRefs: Array<EntityRefOfKeywordInput>;
  marketRefs: Array<EntityRefOfMarketInput>;
  name: Scalars['String'];
  regulationNumber: Scalars['String'];
  standardPlusPcmsClusterRefs: Array<EntityRefOfStandardPlusPcmsClusterInput>;
  summary?: InputMaybe<Scalars['String']>;
  willBeAnonymized: Scalars['Boolean'];
};

export type CreateRegulatoryDocumentInput = {
  dateEffective?: InputMaybe<Scalars['Date']>;
  dateExpiration?: InputMaybe<Scalars['Date']>;
  deletionPeriod: Scalars['Int'];
  documentReferences?: InputMaybe<Array<DocumentReferenceInput>>;
  documentSourceRef?: InputMaybe<EntityRefOfDocumentSourceInput>;
  finalVersionRef?: InputMaybe<EntityRefOfRegulatoryDocumentInput>;
  name: Scalars['String'];
  origin: Origin;
  predecessorRef?: InputMaybe<EntityRefOfRegulatoryDocumentInput>;
  regulationRef?: InputMaybe<EntityRefOfRegulationInput>;
  status: RegulatoryDocumentStatus;
  successorRef?: InputMaybe<EntityRefOfRegulatoryDocumentInput>;
  summary?: InputMaybe<Scalars['String']>;
  topicManagementSummary?: InputMaybe<TopicManagementSummaryInput>;
  willBeAnonymized: Scalars['Boolean'];
  workflow: WorkflowInput;
};

export type CreateRequirementInput = {
  activeTypeMarketRefs?: InputMaybe<Array<EntityRefOfActiveTypeMarketInput>>;
  approvalWorkFlow?: InputMaybe<ApprovalWorkFlowInput>;
  bodyworkVariantRefs?: InputMaybe<Array<EntityRefOfBodyworkVariantInput>>;
  category: RequirementCategory;
  definition?: InputMaybe<Scalars['String']>;
  deletionPeriod: Scalars['Int'];
  documentReferences?: InputMaybe<Array<DocumentReferenceInput>>;
  driveVariantRefs?: InputMaybe<Array<EntityRefOfDriveVariantInput>>;
  engineVariantRefs?: InputMaybe<Array<EntityRefOfEngineVariantInput>>;
  gearboxVariantRefs?: InputMaybe<Array<EntityRefOfGearboxVariantInput>>;
  keywordRefs?: InputMaybe<Array<EntityRefOfKeywordInput>>;
  marketRefs?: InputMaybe<Array<EntityRefOfMarketInput>>;
  name: Scalars['String'];
  parentRef?: InputMaybe<EntityRefOfRequirementInput>;
  requirementId?: InputMaybe<Scalars['String']>;
  status: RequirementStatus;
  successorRef?: InputMaybe<EntityRefOfRequirementInput>;
  systemLevelRefs?: InputMaybe<Array<EntityRefOfSystemLevelInput>>;
  tagRefs?: InputMaybe<Array<EntityRefOfTagInput>>;
  vehicleCategoryRefs?: InputMaybe<Array<EntityRefOfVehicleCategoryInput>>;
  version?: InputMaybe<Scalars['String']>;
  vexClusterRefs: Array<EntityRefOfVexClusterInput>;
  willBeAnonymized: Scalars['Boolean'];
};

export type CreateSimulationInput = {
  generationReference?: InputMaybe<Scalars['String']>;
  modelSeriesReference?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateStandardPlusPcmsClusterInput = {
  name: Scalars['String'];
};

export type CreateStandardPlusPcmsClusterPayload = {
  __typename?: 'CreateStandardPlusPcmsClusterPayload';
  standardPlusPcmsCluster?: Maybe<StandardPlusPcmsCluster>;
};

export type CreateSubscriptionInput = {
  keywordRefs?: InputMaybe<Array<EntityRefOfKeywordInput>>;
  marketRefs?: InputMaybe<Array<EntityRefOfMarketInput>>;
  name: Scalars['String'];
  notificationFormat: NotificationFormat;
  notificationFrequency: NotificationFrequency;
  optOnlyForMainKeywords?: InputMaybe<Scalars['Boolean']>;
  standardPlusPcmsClusterRefs?: InputMaybe<Array<EntityRefOfStandardPlusPcmsClusterInput>>;
};

export type CreateSystemLevelInput = {
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateSystemLevelPayload = {
  __typename?: 'CreateSystemLevelPayload';
  systemLevel?: Maybe<SystemLevel>;
};

export type CreateTagInput = {
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  tag?: Maybe<Tag>;
};

export type CreateVehicleCategoryInput = {
  name: Scalars['String'];
};

export type CreateVehicleCategoryPayload = {
  __typename?: 'CreateVehicleCategoryPayload';
  vehicleCategory?: Maybe<VehicleCategory>;
};

export type CreateVehicleProjectInput = {
  activeTypeMarketRefs: Array<EntityRefOfActiveTypeMarketInput>;
  bodyworkVariantRefs: Array<EntityRefOfBodyworkVariantInput>;
  deletionPeriod: Scalars['Int'];
  derivative?: InputMaybe<Scalars['String']>;
  detVsi?: InputMaybe<Scalars['DateTime']>;
  driveVariantRefs: Array<EntityRefOfDriveVariantInput>;
  engineVariantRefs: Array<EntityRefOfEngineVariantInput>;
  eopVsi?: InputMaybe<Scalars['DateTime']>;
  euLegislation?: InputMaybe<Scalars['Boolean']>;
  gearboxVariantRefs: Array<EntityRefOfGearboxVariantInput>;
  generation: Scalars['String'];
  isElectro?: InputMaybe<Scalars['Boolean']>;
  marketRefs: Array<EntityRefOfMarketInput>;
  modelSeries: Scalars['String'];
  modelYear?: InputMaybe<Scalars['Int']>;
  p10Vsi?: InputMaybe<Scalars['DateTime']>;
  posEtVsi?: InputMaybe<Scalars['DateTime']>;
  posVsi?: InputMaybe<Scalars['DateTime']>;
  sopVsi: Scalars['DateTime'];
  sosVsi?: InputMaybe<Scalars['DateTime']>;
  sysgenVsi?: InputMaybe<Scalars['DateTime']>;
  typStartVsi?: InputMaybe<Scalars['DateTime']>;
  willBeAnonymized: Scalars['Boolean'];
  wvtaVsi?: InputMaybe<Scalars['DateTime']>;
};

export type CreateVexClusterInput = {
  approverRefs: Array<EntityRefOfUserInput>;
  name: Scalars['String'];
  userRefs: Array<EntityRefOfUserInput>;
};

export type CreateVexClusterPayload = {
  __typename?: 'CreateVexClusterPayload';
  vexCluster?: Maybe<VexCluster>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteActiveTypeMarketInput = {
  id: Scalars['ID'];
};

export type DeleteActiveTypeMarketPayload = {
  __typename?: 'DeleteActiveTypeMarketPayload';
  activeTypeMarket?: Maybe<ActiveTypeMarket>;
};

export type DeleteAttachmentCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteAttachmentCategoryPayload = {
  __typename?: 'DeleteAttachmentCategoryPayload';
  attachmentCategory?: Maybe<AttachmentCategory>;
};

export type DeleteBaselineInput = {
  id: Scalars['ID'];
};

export type DeleteBaselinePayload = {
  __typename?: 'DeleteBaselinePayload';
  baseline?: Maybe<Baseline>;
};

export type DeleteBodyworkVariantInput = {
  id: Scalars['ID'];
};

export type DeleteBodyworkVariantPayload = {
  __typename?: 'DeleteBodyworkVariantPayload';
  bodyworkVariant?: Maybe<BodyworkVariant>;
};

export type DeleteCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  category?: Maybe<Category>;
};

export type DeleteConvoluteInput = {
  id: Scalars['ID'];
};

export type DeleteConvolutePayload = {
  __typename?: 'DeleteConvolutePayload';
  convolute?: Maybe<Convolute>;
};

export type DeleteCountryInput = {
  id: Scalars['ID'];
};

export type DeleteCountryPayload = {
  __typename?: 'DeleteCountryPayload';
  country?: Maybe<Country>;
};

export type DeleteDocumentSourceInput = {
  id: Scalars['ID'];
};

export type DeleteDocumentSourcePayload = {
  __typename?: 'DeleteDocumentSourcePayload';
  documentSource?: Maybe<DocumentSource>;
};

export type DeleteDriveVariantInput = {
  id: Scalars['ID'];
};

export type DeleteDriveVariantPayload = {
  __typename?: 'DeleteDriveVariantPayload';
  driveVariant?: Maybe<DriveVariant>;
};

export type DeleteEngineVariantInput = {
  id: Scalars['ID'];
};

export type DeleteEngineVariantPayload = {
  __typename?: 'DeleteEngineVariantPayload';
  engineVariant?: Maybe<EngineVariant>;
};

export type DeleteGdprRuleInput = {
  id: Scalars['ID'];
};

export type DeleteGdprRulePayload = {
  __typename?: 'DeleteGdprRulePayload';
  gdprRule?: Maybe<GdprRule>;
};

export type DeleteGearboxVariantInput = {
  id: Scalars['ID'];
};

export type DeleteGearboxVariantPayload = {
  __typename?: 'DeleteGearboxVariantPayload';
  gearboxVariant?: Maybe<GearboxVariant>;
};

export type DeleteKeywordInput = {
  id: Scalars['ID'];
};

export type DeleteKeywordPayload = {
  __typename?: 'DeleteKeywordPayload';
  keyword?: Maybe<Keyword>;
};

export type DeleteMarketInput = {
  id: Scalars['ID'];
};

export type DeleteMarketPayload = {
  __typename?: 'DeleteMarketPayload';
  market?: Maybe<Market>;
};

export type DeleteReferenceInput = {
  id: Scalars['ID'];
};

export type DeleteReferencePayload = {
  __typename?: 'DeleteReferencePayload';
  reference?: Maybe<Reference>;
};

export type DeleteRegulationClusterInput = {
  id: Scalars['ID'];
};

export type DeleteRegulationClusterPayload = {
  __typename?: 'DeleteRegulationClusterPayload';
  regulationCluster?: Maybe<RegulationCluster>;
};

export type DeleteRegulationCommentaryInput = {
  commentId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteRegulationCommentaryPayload = {
  __typename?: 'DeleteRegulationCommentaryPayload';
  regulation?: Maybe<Regulation>;
};

export type DeleteRegulationFaqInput = {
  faqId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteRegulationFaqPayload = {
  __typename?: 'DeleteRegulationFaqPayload';
  regulation?: Maybe<Regulation>;
};

export type DeleteRegulationInput = {
  id: Scalars['ID'];
};

export type DeleteRegulationPayload = {
  __typename?: 'DeleteRegulationPayload';
  regulation?: Maybe<Regulation>;
};

export type DeleteRegulatoryDocumentCommentaryInput = {
  commentId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteRegulatoryDocumentCommentaryPayload = {
  __typename?: 'DeleteRegulatoryDocumentCommentaryPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type DeleteRegulatoryDocumentFaqInput = {
  faqId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteRegulatoryDocumentFaqPayload = {
  __typename?: 'DeleteRegulatoryDocumentFaqPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type DeleteRegulatoryDocumentInput = {
  id: Scalars['ID'];
};

export type DeleteRegulatoryDocumentParagraphCommentaryInput = {
  commentId: Scalars['ID'];
  paragraphId: Scalars['ID'];
  regulatoryDocumentId: Scalars['ID'];
};

export type DeleteRegulatoryDocumentParagraphCommentaryPayload = {
  __typename?: 'DeleteRegulatoryDocumentParagraphCommentaryPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type DeleteRegulatoryDocumentParagraphFaqInput = {
  faqId: Scalars['ID'];
  paragraphId: Scalars['ID'];
  regulatoryDocumentId: Scalars['ID'];
};

export type DeleteRegulatoryDocumentParagraphFaqPayload = {
  __typename?: 'DeleteRegulatoryDocumentParagraphFaqPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type DeleteRegulatoryDocumentPayload = {
  __typename?: 'DeleteRegulatoryDocumentPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type DeleteRequirementInput = {
  id: Scalars['ID'];
};

export type DeleteRequirementPayload = {
  __typename?: 'DeleteRequirementPayload';
  requirement?: Maybe<Requirement>;
};

export type DeleteSimulationInput = {
  id: Scalars['ID'];
};

export type DeleteSimulationPayload = {
  __typename?: 'DeleteSimulationPayload';
  simulation?: Maybe<Simulation>;
};

export type DeleteStandardPlusPcmsClusterInput = {
  id: Scalars['ID'];
};

export type DeleteStandardPlusPcmsClusterPayload = {
  __typename?: 'DeleteStandardPlusPcmsClusterPayload';
  standardPlusPcmsCluster?: Maybe<StandardPlusPcmsCluster>;
};

export type DeleteSubscriptionInput = {
  id: Scalars['ID'];
};

export type DeleteSubscriptionPayload = {
  __typename?: 'DeleteSubscriptionPayload';
  subscription?: Maybe<Subscription>;
};

export type DeleteSystemLevelInput = {
  id: Scalars['ID'];
};

export type DeleteSystemLevelPayload = {
  __typename?: 'DeleteSystemLevelPayload';
  systemLevel?: Maybe<SystemLevel>;
};

export type DeleteTagInput = {
  id: Scalars['ID'];
};

export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  tag?: Maybe<Tag>;
};

export type DeleteVehicleCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteVehicleCategoryPayload = {
  __typename?: 'DeleteVehicleCategoryPayload';
  vehicleCategory?: Maybe<VehicleCategory>;
};

export type DeleteVehicleProjectInput = {
  id: Scalars['ID'];
};

export type DeleteVehicleProjectPayload = {
  __typename?: 'DeleteVehicleProjectPayload';
  vehicleProject?: Maybe<VehicleProject>;
};

export type DeleteVexClusterInput = {
  id: Scalars['ID'];
};

export type DeleteVexClusterPayload = {
  __typename?: 'DeleteVexClusterPayload';
  vexCluster?: Maybe<VexCluster>;
};

export type DocumentReference = {
  __typename?: 'DocumentReference';
  reference?: Maybe<Reference>;
  referenceDocumentType?: Maybe<Scalars['String']>;
  regulationReferences: Array<Regulation>;
  regulatoryDocumentParagraphReferences: Array<Maybe<RegulatoryDocumentParagraph>>;
  regulatoryDocumentReferences: Array<RegulatoryDocument>;
  requirementReferences: Array<Requirement>;
};

export type DocumentReferenceFilterInput = {
  and?: InputMaybe<Array<DocumentReferenceFilterInput>>;
  or?: InputMaybe<Array<DocumentReferenceFilterInput>>;
  reference?: InputMaybe<EntityRefOfReferenceFilterInput>;
  referenceDocumentType?: InputMaybe<StringOperationFilterInput>;
  regulationReferences?: InputMaybe<ListFilterInputTypeOfEntityRefOfRegulationFilterInput>;
  regulatoryDocumentParagraphReferences?: InputMaybe<ListFilterInputTypeOfEntityRefOfRegulatoryDocumentParagraphFilterInput>;
  regulatoryDocumentReferences?: InputMaybe<ListFilterInputTypeOfEntityRefOfRegulatoryDocumentFilterInput>;
  requirementReferences?: InputMaybe<ListFilterInputTypeOfEntityRefOfRequirementFilterInput>;
};

export type DocumentReferenceInput = {
  reference: EntityRefOfReferenceInput;
  referenceDocumentType?: InputMaybe<Scalars['String']>;
  regulationReferences?: InputMaybe<Array<EntityRefOfRegulationInput>>;
  regulatoryDocumentParagraphReferences?: InputMaybe<Array<EntityRefOfRegulatoryDocumentParagraphInput>>;
  regulatoryDocumentReferences?: InputMaybe<Array<EntityRefOfRegulatoryDocumentInput>>;
  requirementReferences?: InputMaybe<Array<EntityRefOfRequirementInput>>;
};

export type DocumentSource = Entity & NamedEntity & Node & {
  __typename?: 'DocumentSource';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type DocumentSourceNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export enum DocumentStatus {
  NotSplit = 'NOT_SPLIT',
  SplittingError = 'SPLITTING_ERROR',
  SplittingFinished = 'SPLITTING_FINISHED',
  SplittingInProgress = 'SPLITTING_IN_PROGRESS'
}

export type DocumentStatusOperationFilterInput = {
  eq?: InputMaybe<DocumentStatus>;
  in?: InputMaybe<Array<DocumentStatus>>;
  neq?: InputMaybe<DocumentStatus>;
  nin?: InputMaybe<Array<DocumentStatus>>;
};

export type DoubletError = Error & {
  __typename?: 'DoubletError';
  message: Scalars['String'];
};

export type DoubletcheckRegulatoryDocumentFileInput = {
  file: Scalars['Upload'];
};

export type DoubletcheckRegulatoryDocumentFilePayload = {
  __typename?: 'DoubletcheckRegulatoryDocumentFilePayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DriveVariant = Entity & NamedEntity & Node & {
  __typename?: 'DriveVariant';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type DriveVariantFields = {
  __typename?: 'DriveVariantFields';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DriveVariantSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type EngineVariant = Entity & NamedEntity & Node & {
  __typename?: 'EngineVariant';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type EngineVariantSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type Entity = {
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
};

export enum EntityOperation {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type EntityOperationOperationFilterInput = {
  eq?: InputMaybe<EntityOperation>;
  in?: InputMaybe<Array<EntityOperation>>;
  neq?: InputMaybe<EntityOperation>;
  nin?: InputMaybe<Array<EntityOperation>>;
};

export type EntityRefOfActiveTypeMarketInput = {
  id: Scalars['ID'];
};

export type EntityRefOfBodyworkVariantInput = {
  id: Scalars['ID'];
};

export type EntityRefOfCategoryInput = {
  id: Scalars['ID'];
};

export type EntityRefOfCountryInput = {
  id: Scalars['ID'];
};

export type EntityRefOfDocumentSourceInput = {
  id: Scalars['ID'];
};

export type EntityRefOfDriveVariantInput = {
  id: Scalars['ID'];
};

export type EntityRefOfEngineVariantInput = {
  id: Scalars['ID'];
};

export type EntityRefOfGdprRule = {
  __typename?: 'EntityRefOfGdprRule';
  id: Scalars['ID'];
};

export type EntityRefOfGdprRuleInput = {
  id: Scalars['ID'];
};

export type EntityRefOfGdprRuleSortInput = {
  id?: InputMaybe<SortEnumType>;
};

export type EntityRefOfGearboxVariantInput = {
  id: Scalars['ID'];
};

export type EntityRefOfKeyword = {
  __typename?: 'EntityRefOfKeyword';
  id: Scalars['ID'];
};

export type EntityRefOfKeywordFilterInput = {
  and?: InputMaybe<Array<EntityRefOfKeywordFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfKeywordFilterInput>>;
};

export type EntityRefOfKeywordInput = {
  id: Scalars['ID'];
};

export type EntityRefOfMarket = {
  __typename?: 'EntityRefOfMarket';
  id: Scalars['ID'];
};

export type EntityRefOfMarketInput = {
  id: Scalars['ID'];
};

export type EntityRefOfReferenceFilterInput = {
  and?: InputMaybe<Array<EntityRefOfReferenceFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfReferenceFilterInput>>;
};

export type EntityRefOfReferenceInput = {
  id: Scalars['ID'];
};

export type EntityRefOfRegulationClusterInput = {
  id: Scalars['ID'];
};

export type EntityRefOfRegulationFilterInput = {
  and?: InputMaybe<Array<EntityRefOfRegulationFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfRegulationFilterInput>>;
};

export type EntityRefOfRegulationInput = {
  id: Scalars['ID'];
};

export type EntityRefOfRegulatoryDocumentFilterInput = {
  and?: InputMaybe<Array<EntityRefOfRegulatoryDocumentFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfRegulatoryDocumentFilterInput>>;
};

export type EntityRefOfRegulatoryDocumentInput = {
  id: Scalars['ID'];
};

export type EntityRefOfRegulatoryDocumentParagraphFilterInput = {
  and?: InputMaybe<Array<EntityRefOfRegulatoryDocumentParagraphFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfRegulatoryDocumentParagraphFilterInput>>;
};

export type EntityRefOfRegulatoryDocumentParagraphInput = {
  id: Scalars['ID'];
};

export type EntityRefOfRequirementFilterInput = {
  and?: InputMaybe<Array<EntityRefOfRequirementFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfRequirementFilterInput>>;
};

export type EntityRefOfRequirementInput = {
  id: Scalars['ID'];
};

export type EntityRefOfStandardPlusPcmsCluster = {
  __typename?: 'EntityRefOfStandardPlusPcmsCluster';
  id: Scalars['ID'];
};

export type EntityRefOfStandardPlusPcmsClusterInput = {
  id: Scalars['ID'];
};

export type EntityRefOfSystemLevel = {
  __typename?: 'EntityRefOfSystemLevel';
  id: Scalars['ID'];
};

export type EntityRefOfSystemLevelInput = {
  id: Scalars['ID'];
};

export type EntityRefOfTagInput = {
  id: Scalars['ID'];
};

export type EntityRefOfUser = {
  __typename?: 'EntityRefOfUser';
  id: Scalars['ID'];
};

export type EntityRefOfUserFilterInput = {
  and?: InputMaybe<Array<EntityRefOfUserFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfUserFilterInput>>;
};

export type EntityRefOfUserInput = {
  id: Scalars['ID'];
};

export type EntityRefOfVehicleCategoryInput = {
  id: Scalars['ID'];
};

export type EntityRefOfVexClusterFilterInput = {
  and?: InputMaybe<Array<EntityRefOfVexClusterFilterInput>>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<EntityRefOfVexClusterFilterInput>>;
};

export type EntityRefOfVexClusterInput = {
  id: Scalars['ID'];
};

export type EntityRefOfVkoInput = {
  id: Scalars['ID'];
};

export type Error = {
  message: Scalars['String'];
};

export type ExportRequirementsInput = {
  requirementIds: Array<Scalars['ID']>;
};

export type ExportRequirementsPayload = {
  __typename?: 'ExportRequirementsPayload';
  blobRef?: Maybe<BlobRef>;
};

export type Faq = Entity & {
  __typename?: 'Faq';
  answer?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  question?: Maybe<Scalars['String']>;
};

export type FaqFilterInput = {
  and?: InputMaybe<Array<FaqFilterInput>>;
  answer?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<FaqFilterInput>>;
  question?: InputMaybe<StringOperationFilterInput>;
};

export type FaqInput = {
  answer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
};

export type Favorites = {
  __typename?: 'Favorites';
  paragraphs: Array<RegulatoryDocumentParagraph>;
  regulations: Array<Regulation>;
  regulatoryDocuments: Array<RegulatoryDocument>;
  requirements: Array<Requirement>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type FullTextDocumentResult = {
  __typename?: 'FullTextDocumentResult';
  paragraphs: Array<RegulatoryDocumentParagraph>;
  regulatoryDocuments: Array<RegulatoryDocument>;
};

export type FullTextPogisDocumentResult = {
  __typename?: 'FullTextPogisDocumentResult';
  pogisDocuments: Array<PogisDocument>;
};

export type FullTextRegulationResult = {
  __typename?: 'FullTextRegulationResult';
  regulations: Array<Regulation>;
};

export type GdprRule = Entity & NamedEntity & Node & {
  __typename?: 'GdprRule';
  anonymize: Scalars['Boolean'];
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deletionPeriod: Scalars['Int'];
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type GearboxVariant = Entity & NamedEntity & Node & {
  __typename?: 'GearboxVariant';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type GearboxVariantSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type GetHistoryOutput = Entity & {
  __typename?: 'GetHistoryOutput';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  totalCount: Scalars['Int'];
};

export type IdOperationFilterInput = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  neq?: InputMaybe<Scalars['ID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ImportRegulatoryDocumentParagraphInput = {
  categoryRefs: Array<EntityRefOfCategoryInput>;
  comments?: InputMaybe<Array<CommentaryInput>>;
  comprehensive?: InputMaybe<Scalars['Date']>;
  dateNewRegistration?: InputMaybe<Scalars['Date']>;
  dateNewTypes?: InputMaybe<Scalars['Date']>;
  driveVariantRefs: Array<EntityRefOfDriveVariantInput>;
  elements: Array<RegulatoryDocumentParagraphElementInput>;
  enumeration: Scalars['String'];
  faqs?: InputMaybe<Array<FaqInput>>;
  id?: InputMaybe<Scalars['ID']>;
  isFootnote: Scalars['Boolean'];
  keywordAssignments?: InputMaybe<Array<KeywordAssignmentInput>>;
  level: Scalars['Int'];
  modelYear?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  phaseIn?: InputMaybe<Array<PhaseDateInput>>;
  phaseOut?: InputMaybe<Array<PhaseDateInput>>;
  summary?: InputMaybe<Scalars['String']>;
  tagRefs: Array<EntityRefOfTagInput>;
  vehicleCategoryRefs: Array<EntityRefOfVehicleCategoryInput>;
};

export type ImportRegulatoryDocumentParagraphsInput = {
  paragraphs?: InputMaybe<Array<InputMaybe<ImportRegulatoryDocumentParagraphInput>>>;
  regulatoryDocumentId: Scalars['ID'];
};

export type ImportRegulatoryDocumentParagraphsPayload = {
  __typename?: 'ImportRegulatoryDocumentParagraphsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Keyword = Entity & NamedEntity & Node & {
  __typename?: 'Keyword';
  approvers: Array<User>;
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  regulationClusters: Array<RegulationCluster>;
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
  vexClusters: Array<VexCluster>;
  vkos: Array<User>;
};


export type KeywordNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type KeywordAssignment = {
  __typename?: 'KeywordAssignment';
  keyword: Keyword;
  vexClusterAssignments: Array<VexClusterAssignment>;
};

export type KeywordAssignmentFilterInput = {
  and?: InputMaybe<Array<KeywordAssignmentFilterInput>>;
  keyword?: InputMaybe<EntityRefOfKeywordFilterInput>;
  or?: InputMaybe<Array<KeywordAssignmentFilterInput>>;
  vexClusterAssignments?: InputMaybe<ListFilterInputTypeOfVexClusterAssignmentFilterInput>;
};

export type KeywordAssignmentInput = {
  keyword: EntityRefOfKeywordInput;
  vexClusterAssignments: Array<VexClusterAssignmentInput>;
};

export type KeywordSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum KeywordStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Finalized = 'FINALIZED',
  New = 'NEW',
  NoImpact = 'NO_IMPACT',
  NoRequirements = 'NO_REQUIREMENTS',
  RequirementsDerived = 'REQUIREMENTS_DERIVED'
}

export type KeywordStatusOperationFilterInput = {
  eq?: InputMaybe<KeywordStatus>;
  in?: InputMaybe<Array<KeywordStatus>>;
  neq?: InputMaybe<KeywordStatus>;
  nin?: InputMaybe<Array<KeywordStatus>>;
};

export type ListFilterInputTypeOfCommentaryFilterInput = {
  all?: InputMaybe<CommentaryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CommentaryFilterInput>;
  some?: InputMaybe<CommentaryFilterInput>;
};

export type ListFilterInputTypeOfDocumentReferenceFilterInput = {
  all?: InputMaybe<DocumentReferenceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DocumentReferenceFilterInput>;
  some?: InputMaybe<DocumentReferenceFilterInput>;
};

export type ListFilterInputTypeOfEntityRefOfRegulationFilterInput = {
  all?: InputMaybe<EntityRefOfRegulationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EntityRefOfRegulationFilterInput>;
  some?: InputMaybe<EntityRefOfRegulationFilterInput>;
};

export type ListFilterInputTypeOfEntityRefOfRegulatoryDocumentFilterInput = {
  all?: InputMaybe<EntityRefOfRegulatoryDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EntityRefOfRegulatoryDocumentFilterInput>;
  some?: InputMaybe<EntityRefOfRegulatoryDocumentFilterInput>;
};

export type ListFilterInputTypeOfEntityRefOfRegulatoryDocumentParagraphFilterInput = {
  all?: InputMaybe<EntityRefOfRegulatoryDocumentParagraphFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EntityRefOfRegulatoryDocumentParagraphFilterInput>;
  some?: InputMaybe<EntityRefOfRegulatoryDocumentParagraphFilterInput>;
};

export type ListFilterInputTypeOfEntityRefOfRequirementFilterInput = {
  all?: InputMaybe<EntityRefOfRequirementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EntityRefOfRequirementFilterInput>;
  some?: InputMaybe<EntityRefOfRequirementFilterInput>;
};

export type ListFilterInputTypeOfEntityRefOfUserFilterInput = {
  all?: InputMaybe<EntityRefOfUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EntityRefOfUserFilterInput>;
  some?: InputMaybe<EntityRefOfUserFilterInput>;
};

export type ListFilterInputTypeOfFaqFilterInput = {
  all?: InputMaybe<FaqFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FaqFilterInput>;
  some?: InputMaybe<FaqFilterInput>;
};

export type ListFilterInputTypeOfKeywordAssignmentFilterInput = {
  all?: InputMaybe<KeywordAssignmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<KeywordAssignmentFilterInput>;
  some?: InputMaybe<KeywordAssignmentFilterInput>;
};

export type ListFilterInputTypeOfPhaseDateFilterInput = {
  all?: InputMaybe<PhaseDateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PhaseDateFilterInput>;
  some?: InputMaybe<PhaseDateFilterInput>;
};

export type ListFilterInputTypeOfRegulatoryDocumentParagraphElementFilterInput = {
  all?: InputMaybe<RegulatoryDocumentParagraphElementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RegulatoryDocumentParagraphElementFilterInput>;
  some?: InputMaybe<RegulatoryDocumentParagraphElementFilterInput>;
};

export type ListFilterInputTypeOfRegulatoryDocumentParagraphFilterInput = {
  all?: InputMaybe<RegulatoryDocumentParagraphFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RegulatoryDocumentParagraphFilterInput>;
  some?: InputMaybe<RegulatoryDocumentParagraphFilterInput>;
};

export type ListFilterInputTypeOfVexClusterAssignmentFilterInput = {
  all?: InputMaybe<VexClusterAssignmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VexClusterAssignmentFilterInput>;
  some?: InputMaybe<VexClusterAssignmentFilterInput>;
};

export type ListFloatOperationFilterInput = {
  all?: InputMaybe<FloatOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FloatOperationFilterInput>;
  some?: InputMaybe<FloatOperationFilterInput>;
};

export type Market = Entity & NamedEntity & Node & {
  __typename?: 'Market';
  attachments?: Maybe<Array<AttachmentRef>>;
  countries: Array<Country>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  regulationNamingTemplates: Array<NamingTemplate>;
  regulatoryDocumentNamingTemplates: Array<NamingTemplate>;
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type MarketNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type MarketSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type MergeReapprovalRegulatoryDocumentChangesInput = {
  finalRegulatoryDocumentId: Scalars['ID'];
  modifiedRegulatoryDocumentId: Scalars['ID'];
};

export type MergeReapprovalRegulatoryDocumentChangesPayload = {
  __typename?: 'MergeReapprovalRegulatoryDocumentChangesPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFavorite: User;
  addOrUpdateTableConfiguration: AddOrUpdateTableConfigurationPayload;
  addRegulationCommentary: Regulation;
  addRegulationFaq: Regulation;
  addRegulatoryDocumentCommentary: RegulatoryDocument;
  addRegulatoryDocumentFaq: RegulatoryDocument;
  addRegulatoryDocumentParagraphCommentary: AddRegulatoryDocumentParagraphCommentaryPayload;
  addRegulatoryDocumentParagraphFaq: AddRegulatoryDocumentParagraphFaqPayload;
  assignRegulatoryDocumentParagraphsRequirements: AssignRegulatoryDocumentParagraphsRequirementsPayload;
  assignRegulatoryDocumentParagraphsRequirementsDocumentCompare: AssignRegulatoryDocumentParagraphsRequirementsDocumentComparePayload;
  assignRegulatoryDocumentParagraphsTags: AssignRegulatoryDocumentParagraphsTagsPayload;
  assignRequirements: AssignRequirementsPayload;
  assignRequirementsBatchManually: AssignRequirementsBatchManuallyPayload;
  assignRequirementsManually: AssignRequirementsManuallyPayload;
  assignVehicleProjectPermissions: AssignVehicleProjectPermissionsPayload;
  baseLineNameByModelAndSeries?: Maybe<Scalars['String']>;
  checkVersionNameRegulatoryDocument: CheckVersionNameRegulatoryDocumentPayload;
  clearFieldForAllParagraphs: ClearFieldForAllParagraphsPayload;
  clearFieldForSelectedParagraphs: ClearFieldForSelectedParagraphsPayload;
  cloneAllParagraphsForRegulatoryDocument: CloneAllParagraphsForRegulatoryDocumentPayload;
  cloneRegulatoryDocument: RegulatoryDocumentPayload;
  concludeRegulatoryDocument: ConcludeRegulatoryDocumentPayload;
  createActiveTypeMarket: CreateActiveTypeMarketPayload;
  createAttachmentCategory: CreateAttachmentCategoryPayload;
  createBaseLine: BaselineCreateResult;
  createBaseLineByModelAndSeries?: Maybe<BaselineCreateResult>;
  createBaseline: CreateBaselinePayload;
  createBodyworkVariant: CreateBodyworkVariantPayload;
  createCategory: CreateCategoryPayload;
  createConvolute: CreateConvolutePayload;
  createCountry: CreateCountryPayload;
  createDocumentSource: CreateDocumentSourcePayload;
  createDriveVariant: CreateDriveVariantPayload;
  createEngineVariant: CreateEngineVariantPayload;
  createGdprRule: CreateGdprRulePayload;
  createGearboxVariant: CreateGearboxVariantPayload;
  createInterimRequirement: RegulatoryDocument;
  createKeyword: CreateKeywordPayload;
  createMarket: CreateMarketPayload;
  createPogisDocument: PogisDocumentPayload;
  createReference: CreateReferencePayload;
  createRegulation: RegulationPayload;
  createRegulationCluster: CreateRegulationClusterPayload;
  createRegulatoryDocument: RegulatoryDocumentPayload;
  createRequirement: RequirementPayload;
  createSimulation: SimulationPayload;
  createStandardPlusPcmsCluster: CreateStandardPlusPcmsClusterPayload;
  createSubscription: SubscriptionPayload;
  createSystemLevel: CreateSystemLevelPayload;
  createTag: CreateTagPayload;
  createVehicleCategory: CreateVehicleCategoryPayload;
  createVehicleProject: VehicleProjectPayload;
  createVexCluster: CreateVexClusterPayload;
  deleteActiveTypeMarket: DeleteActiveTypeMarketPayload;
  deleteAttachmentCategory: DeleteAttachmentCategoryPayload;
  deleteBaseline: DeleteBaselinePayload;
  deleteBodyworkVariant: DeleteBodyworkVariantPayload;
  deleteCategory: DeleteCategoryPayload;
  deleteConvolute: DeleteConvolutePayload;
  deleteCountry: DeleteCountryPayload;
  deleteDocumentSource: DeleteDocumentSourcePayload;
  deleteDriveVariant: DeleteDriveVariantPayload;
  deleteEngineVariant: DeleteEngineVariantPayload;
  deleteGdprRule: DeleteGdprRulePayload;
  deleteGearboxVariant: DeleteGearboxVariantPayload;
  deleteKeyword: DeleteKeywordPayload;
  deleteMarket: DeleteMarketPayload;
  deleteReference: DeleteReferencePayload;
  deleteRegulation: DeleteRegulationPayload;
  deleteRegulationCluster: DeleteRegulationClusterPayload;
  deleteRegulationCommentary: DeleteRegulationCommentaryPayload;
  deleteRegulationFaq: DeleteRegulationFaqPayload;
  deleteRegulatoryDocument: DeleteRegulatoryDocumentPayload;
  deleteRegulatoryDocumentCommentary: DeleteRegulatoryDocumentCommentaryPayload;
  deleteRegulatoryDocumentFaq: DeleteRegulatoryDocumentFaqPayload;
  deleteRegulatoryDocumentParagraphCommentary: DeleteRegulatoryDocumentParagraphCommentaryPayload;
  deleteRegulatoryDocumentParagraphFaq: DeleteRegulatoryDocumentParagraphFaqPayload;
  deleteRequirement: DeleteRequirementPayload;
  deleteSimulation: DeleteSimulationPayload;
  deleteStandardPlusPcmsCluster: DeleteStandardPlusPcmsClusterPayload;
  deleteSubscription: DeleteSubscriptionPayload;
  deleteSystemLevel: DeleteSystemLevelPayload;
  deleteTag: DeleteTagPayload;
  deleteVehicleCategory: DeleteVehicleCategoryPayload;
  deleteVehicleProject: DeleteVehicleProjectPayload;
  deleteVexCluster: DeleteVexClusterPayload;
  dismantleDocument: Scalars['Boolean'];
  doubletcheckRegulatoryDocumentFile: DoubletcheckRegulatoryDocumentFilePayload;
  exportRequirements: ExportRequirementsPayload;
  importRegulatoryDocumentParagraphs: ImportRegulatoryDocumentParagraphsPayload;
  mergeReapprovalRegulatoryDocumentChanges: MergeReapprovalRegulatoryDocumentChangesPayload;
  pathUploadRegulatoryDocumentAsset: Scalars['String'];
  reassignAllRegulatoryDocumentsForClonedRequirement: ReassignAllRegulatoryDocumentsForClonedRequirementPayload;
  removeFavorite: User;
  removeRegulatoryDocumentParagraphsRequirements: RemoveRegulatoryDocumentParagraphsRequirementsPayload;
  removeRequirements: RemoveRequirementsPayload;
  removeRequirementsBatch: RemoveRequirementsBatchPayload;
  removeVehicleProjectPermissions: RemoveVehicleProjectPermissionsPayload;
  renameBaseline: BaselineCreateResult;
  syncNewVexClusters: SyncNewVexClustersPayload;
  updateActiveTypeMarket: UpdateActiveTypeMarketPayload;
  updateAttachmentCategory: UpdateAttachmentCategoryPayload;
  updateBaseline: UpdateBaselinePayload;
  updateBodyworkVariant: UpdateBodyworkVariantPayload;
  updateCategory: UpdateCategoryPayload;
  updateConvolute: UpdateConvolutePayload;
  updateCountry: UpdateCountryPayload;
  updateDocumentSource: UpdateDocumentSourcePayload;
  updateDriveVariant: UpdateDriveVariantPayload;
  updateEngineVariant: UpdateEngineVariantPayload;
  updateGdprRule: UpdateGdprRulePayload;
  updateGearboxVariant: UpdateGearboxVariantPayload;
  updateInterimRequirementStatus: RequirementPayload;
  updateKeyword: UpdateKeywordPayload;
  updateMarket: UpdateMarketPayload;
  updateParagraphAttachments: ParagraphAttachmentsPayload;
  updatePogisDocument: PogisDocumentPayload;
  updatePogisDocumentAsset: PogisDocumentAttachmentsPayload;
  updateReference: UpdateReferencePayload;
  updateRegulation: RegulationPayload;
  updateRegulationAttachments: RegulationAttachmentsPayload;
  updateRegulationCluster: UpdateRegulationClusterPayload;
  updateRegulatoryDocument: RegulatoryDocumentPayload;
  updateRegulatoryDocumentAttachments: RegulatoryDocumentAttachmentsPayload;
  updateRegulatoryDocumentParagraph: UpdateRegulatoryDocumentParagraphPayload;
  updateRegulatoryDocumentParagraphBatch: UpdateRegulatoryDocumentParagraphBatchPayload;
  updateRegulatoryDocumentParagraphs: UpdateRegulatoryDocumentParagraphsPayload;
  updateRegulatoryDocumentStatus: UpdateRegulatoryDocumentStatusPayload;
  updateRegulatoryDocumentWorkflowStatus: RegulatoryDocument;
  updateRequirement: RequirementPayload;
  updateRequirementAttachments: RequirementAttachmentsPayload;
  updateRequirementsVersions: UpdateRequirementsVersionsPayload;
  updateSimulation: SimulationPayload;
  updateStandardPlusPcmsCluster: UpdateStandardPlusPcmsClusterPayload;
  updateSubscription: SubscriptionPayload;
  updateSystemLevel: UpdateSystemLevelPayload;
  updateTag: UpdateTagPayload;
  updateVehicleCategory: UpdateVehicleCategoryPayload;
  updateVehicleProject: VehicleProjectPayload;
  updateVexCluster: UpdateVexClusterPayload;
  updateVexClusterAssignment: UpdateVexClusterAssignmentPayload;
  updateVexClusterAssignmentBatch: UpdateVexClusterAssignmentBatchPayload;
  uploadRegulatoryDocumentAsset: RegulatoryDocumentAssetPayload;
  uploadRegulatoryDocumentFile: RegulatoryDocumentFilePayload;
};


export type MutationAddFavoriteArgs = {
  id: Scalars['ID'];
};


export type MutationAddOrUpdateTableConfigurationArgs = {
  input: AddOrUpdateTableConfigurationInput;
};


export type MutationAddRegulationCommentaryArgs = {
  input: AddCommentaryInput;
};


export type MutationAddRegulationFaqArgs = {
  input: AddFaqInput;
};


export type MutationAddRegulatoryDocumentCommentaryArgs = {
  input: AddCommentaryInput;
};


export type MutationAddRegulatoryDocumentFaqArgs = {
  input: AddFaqInput;
};


export type MutationAddRegulatoryDocumentParagraphCommentaryArgs = {
  input: AddRegulatoryDocumentParagraphCommentaryInput;
};


export type MutationAddRegulatoryDocumentParagraphFaqArgs = {
  input: AddRegulatoryDocumentParagraphFaqInput;
};


export type MutationAssignRegulatoryDocumentParagraphsRequirementsArgs = {
  input: AssignRegulatoryDocumentParagraphsRequirementsInput;
};


export type MutationAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareArgs = {
  input: AssignRegulatoryDocumentParagraphsRequirementsDocumentCompareInput;
};


export type MutationAssignRegulatoryDocumentParagraphsTagsArgs = {
  input: AssignRegulatoryDocumentParagraphsTagsInput;
};


export type MutationAssignRequirementsArgs = {
  input: AssignRequirementsInput;
};


export type MutationAssignRequirementsBatchManuallyArgs = {
  input: AssignRequirementsBatchManuallyInput;
};


export type MutationAssignRequirementsManuallyArgs = {
  input: AssignRequirementsManuallyInput;
};


export type MutationAssignVehicleProjectPermissionsArgs = {
  input: AssignVehicleProjectPermissionsInput;
};


export type MutationBaseLineNameByModelAndSeriesArgs = {
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
};


export type MutationCheckVersionNameRegulatoryDocumentArgs = {
  input: CheckVersionNameRegulatoryDocumentInput;
};


export type MutationClearFieldForAllParagraphsArgs = {
  input: ClearFieldForAllParagraphsInput;
};


export type MutationClearFieldForSelectedParagraphsArgs = {
  input: ClearFieldForSelectedParagraphsInput;
};


export type MutationCloneAllParagraphsForRegulatoryDocumentArgs = {
  input: CloneAllParagraphsForRegulatoryDocumentInput;
};


export type MutationCloneRegulatoryDocumentArgs = {
  input: CloneRegulatoryDocumentInput;
};


export type MutationConcludeRegulatoryDocumentArgs = {
  input: ConcludeRegulatoryDocumentInput;
};


export type MutationCreateActiveTypeMarketArgs = {
  input: CreateActiveTypeMarketInput;
};


export type MutationCreateAttachmentCategoryArgs = {
  input: CreateAttachmentCategoryInput;
};


export type MutationCreateBaseLineArgs = {
  name: Scalars['String'];
  vehicleProjectId: Scalars['ID'];
};


export type MutationCreateBaseLineByModelAndSeriesArgs = {
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateBaselineArgs = {
  input: CreateBaselineInput;
};


export type MutationCreateBodyworkVariantArgs = {
  input: CreateBodyworkVariantInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateConvoluteArgs = {
  input: CreateConvoluteInput;
};


export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};


export type MutationCreateDocumentSourceArgs = {
  input: CreateDocumentSourceInput;
};


export type MutationCreateDriveVariantArgs = {
  input: CreateDriveVariantInput;
};


export type MutationCreateEngineVariantArgs = {
  input: CreateEngineVariantInput;
};


export type MutationCreateGdprRuleArgs = {
  input: CreateGdprRuleInput;
};


export type MutationCreateGearboxVariantArgs = {
  input: CreateGearboxVariantInput;
};


export type MutationCreateInterimRequirementArgs = {
  boolCreateInterimRequirementForce?: InputMaybe<Scalars['Boolean']>;
  regulatoryDocumentId: Scalars['ID'];
};


export type MutationCreateKeywordArgs = {
  input: CreateKeywordInput;
};


export type MutationCreateMarketArgs = {
  input: CreateMarketInput;
};


export type MutationCreatePogisDocumentArgs = {
  input: CreatePogisDocumentInput;
};


export type MutationCreateReferenceArgs = {
  input: CreateReferenceInput;
};


export type MutationCreateRegulationArgs = {
  input: CreateRegulationInput;
};


export type MutationCreateRegulationClusterArgs = {
  input: CreateRegulationClusterInput;
};


export type MutationCreateRegulatoryDocumentArgs = {
  input: CreateRegulatoryDocumentInput;
  reapprovingVKO?: InputMaybe<EntityRefOfUserInput>;
};


export type MutationCreateRequirementArgs = {
  input: CreateRequirementInput;
};


export type MutationCreateSimulationArgs = {
  input: CreateSimulationInput;
};


export type MutationCreateStandardPlusPcmsClusterArgs = {
  input: CreateStandardPlusPcmsClusterInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateSystemLevelArgs = {
  input: CreateSystemLevelInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateVehicleCategoryArgs = {
  input: CreateVehicleCategoryInput;
};


export type MutationCreateVehicleProjectArgs = {
  input: CreateVehicleProjectInput;
};


export type MutationCreateVexClusterArgs = {
  input: CreateVexClusterInput;
};


export type MutationDeleteActiveTypeMarketArgs = {
  input: DeleteActiveTypeMarketInput;
};


export type MutationDeleteAttachmentCategoryArgs = {
  input: DeleteAttachmentCategoryInput;
};


export type MutationDeleteBaselineArgs = {
  input: DeleteBaselineInput;
};


export type MutationDeleteBodyworkVariantArgs = {
  input: DeleteBodyworkVariantInput;
};


export type MutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};


export type MutationDeleteConvoluteArgs = {
  input: DeleteConvoluteInput;
};


export type MutationDeleteCountryArgs = {
  input: DeleteCountryInput;
};


export type MutationDeleteDocumentSourceArgs = {
  input: DeleteDocumentSourceInput;
};


export type MutationDeleteDriveVariantArgs = {
  input: DeleteDriveVariantInput;
};


export type MutationDeleteEngineVariantArgs = {
  input: DeleteEngineVariantInput;
};


export type MutationDeleteGdprRuleArgs = {
  input: DeleteGdprRuleInput;
};


export type MutationDeleteGearboxVariantArgs = {
  input: DeleteGearboxVariantInput;
};


export type MutationDeleteKeywordArgs = {
  input: DeleteKeywordInput;
};


export type MutationDeleteMarketArgs = {
  input: DeleteMarketInput;
};


export type MutationDeleteReferenceArgs = {
  input: DeleteReferenceInput;
};


export type MutationDeleteRegulationArgs = {
  input: DeleteRegulationInput;
};


export type MutationDeleteRegulationClusterArgs = {
  input: DeleteRegulationClusterInput;
};


export type MutationDeleteRegulationCommentaryArgs = {
  input: DeleteRegulationCommentaryInput;
};


export type MutationDeleteRegulationFaqArgs = {
  input: DeleteRegulationFaqInput;
};


export type MutationDeleteRegulatoryDocumentArgs = {
  input: DeleteRegulatoryDocumentInput;
};


export type MutationDeleteRegulatoryDocumentCommentaryArgs = {
  input: DeleteRegulatoryDocumentCommentaryInput;
};


export type MutationDeleteRegulatoryDocumentFaqArgs = {
  input: DeleteRegulatoryDocumentFaqInput;
};


export type MutationDeleteRegulatoryDocumentParagraphCommentaryArgs = {
  input: DeleteRegulatoryDocumentParagraphCommentaryInput;
};


export type MutationDeleteRegulatoryDocumentParagraphFaqArgs = {
  input: DeleteRegulatoryDocumentParagraphFaqInput;
};


export type MutationDeleteRequirementArgs = {
  input: DeleteRequirementInput;
};


export type MutationDeleteSimulationArgs = {
  input: DeleteSimulationInput;
};


export type MutationDeleteStandardPlusPcmsClusterArgs = {
  input: DeleteStandardPlusPcmsClusterInput;
};


export type MutationDeleteSubscriptionArgs = {
  input: DeleteSubscriptionInput;
};


export type MutationDeleteSystemLevelArgs = {
  input: DeleteSystemLevelInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationDeleteVehicleCategoryArgs = {
  input: DeleteVehicleCategoryInput;
};


export type MutationDeleteVehicleProjectArgs = {
  input: DeleteVehicleProjectInput;
};


export type MutationDeleteVexClusterArgs = {
  input: DeleteVexClusterInput;
};


export type MutationDismantleDocumentArgs = {
  fastTrack: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationDoubletcheckRegulatoryDocumentFileArgs = {
  input: DoubletcheckRegulatoryDocumentFileInput;
};


export type MutationExportRequirementsArgs = {
  input: ExportRequirementsInput;
};


export type MutationImportRegulatoryDocumentParagraphsArgs = {
  input: ImportRegulatoryDocumentParagraphsInput;
};


export type MutationMergeReapprovalRegulatoryDocumentChangesArgs = {
  input: MergeReapprovalRegulatoryDocumentChangesInput;
};


export type MutationPathUploadRegulatoryDocumentAssetArgs = {
  assetId: Scalars['String'];
  regulatoryDocumentId: Scalars['ID'];
};


export type MutationReassignAllRegulatoryDocumentsForClonedRequirementArgs = {
  input: ReassignAllRegulatoryDocumentsForClonedRequirementInput;
};


export type MutationRemoveFavoriteArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveRegulatoryDocumentParagraphsRequirementsArgs = {
  input: RemoveRegulatoryDocumentParagraphsRequirementsInput;
};


export type MutationRemoveRequirementsArgs = {
  input: RemoveRequirementsInput;
};


export type MutationRemoveRequirementsBatchArgs = {
  input: RemoveRequirementsBatchInput;
};


export type MutationRemoveVehicleProjectPermissionsArgs = {
  input: RemoveVehicleProjectPermissionsInput;
};


export type MutationRenameBaselineArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationSyncNewVexClustersArgs = {
  input: SyncNewVexClustersInput;
};


export type MutationUpdateActiveTypeMarketArgs = {
  input: UpdateActiveTypeMarketInput;
};


export type MutationUpdateAttachmentCategoryArgs = {
  input: UpdateAttachmentCategoryInput;
};


export type MutationUpdateBaselineArgs = {
  input: UpdateBaselineInput;
};


export type MutationUpdateBodyworkVariantArgs = {
  input: UpdateBodyworkVariantInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateConvoluteArgs = {
  input: UpdateConvoluteInput;
};


export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};


export type MutationUpdateDocumentSourceArgs = {
  input: UpdateDocumentSourceInput;
};


export type MutationUpdateDriveVariantArgs = {
  input: UpdateDriveVariantInput;
};


export type MutationUpdateEngineVariantArgs = {
  input: UpdateEngineVariantInput;
};


export type MutationUpdateGdprRuleArgs = {
  input: UpdateGdprRuleInput;
};


export type MutationUpdateGearboxVariantArgs = {
  input: UpdateGearboxVariantInput;
};


export type MutationUpdateInterimRequirementStatusArgs = {
  regulatoryDocumentId: Scalars['ID'];
};


export type MutationUpdateKeywordArgs = {
  input: UpdateKeywordInput;
};


export type MutationUpdateMarketArgs = {
  input: UpdateMarketInput;
};


export type MutationUpdateParagraphAttachmentsArgs = {
  input: UpdateParagraphAttachmentsInput;
};


export type MutationUpdatePogisDocumentArgs = {
  input: UpdatePogisDocumentInput;
};


export type MutationUpdatePogisDocumentAssetArgs = {
  input: UpdatePogisDocumentAssetInput;
};


export type MutationUpdateReferenceArgs = {
  input: UpdateReferenceInput;
};


export type MutationUpdateRegulationArgs = {
  input: UpdateRegulationInput;
};


export type MutationUpdateRegulationAttachmentsArgs = {
  input: UpdateRegulationAttachmentsInput;
};


export type MutationUpdateRegulationClusterArgs = {
  input: UpdateRegulationClusterInput;
};


export type MutationUpdateRegulatoryDocumentArgs = {
  clearingVKOs?: InputMaybe<Array<EntityRefOfUserInput>>;
  input: UpdateRegulatoryDocumentInput;
  reapprovingVKO?: InputMaybe<EntityRefOfUserInput>;
};


export type MutationUpdateRegulatoryDocumentAttachmentsArgs = {
  input: UpdateRegulatoryDocumentAttachmentsInput;
};


export type MutationUpdateRegulatoryDocumentParagraphArgs = {
  input: UpdateRegulatoryDocumentParagraphInput;
};


export type MutationUpdateRegulatoryDocumentParagraphBatchArgs = {
  input: UpdateRegulatoryDocumentParagraphBatchInput;
};


export type MutationUpdateRegulatoryDocumentParagraphsArgs = {
  input: UpdateRegulatoryDocumentParagraphsInput;
};


export type MutationUpdateRegulatoryDocumentStatusArgs = {
  input: UpdateRegulatoryDocumentStatusInput;
};


export type MutationUpdateRegulatoryDocumentWorkflowStatusArgs = {
  regulatoryDocumentId: Scalars['ID'];
  workflowStatus: WorkflowStatus;
};


export type MutationUpdateRequirementArgs = {
  input: UpdateRequirementInput;
};


export type MutationUpdateRequirementAttachmentsArgs = {
  input: UpdateRequirementAttachmentsInput;
};


export type MutationUpdateRequirementsVersionsArgs = {
  input: UpdateRequirementsVersionsInput;
};


export type MutationUpdateSimulationArgs = {
  input: UpdateSimulationInput;
};


export type MutationUpdateStandardPlusPcmsClusterArgs = {
  input: UpdateStandardPlusPcmsClusterInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateSystemLevelArgs = {
  input: UpdateSystemLevelInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateVehicleCategoryArgs = {
  input: UpdateVehicleCategoryInput;
};


export type MutationUpdateVehicleProjectArgs = {
  input: UpdateVehicleProjectInput;
};


export type MutationUpdateVexClusterArgs = {
  input: UpdateVexClusterInput;
};


export type MutationUpdateVexClusterAssignmentArgs = {
  input: UpdateVexClusterAssignmentInput;
};


export type MutationUpdateVexClusterAssignmentBatchArgs = {
  input: UpdateVexClusterAssignmentBatchInput;
};


export type MutationUploadRegulatoryDocumentAssetArgs = {
  input: UploadRegulatoryDocumentAssetInput;
};


export type MutationUploadRegulatoryDocumentFileArgs = {
  input: UploadRegulatoryDocumentFileInput;
};

export type NamedEntity = {
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type NamingTemplate = {
  __typename?: 'NamingTemplate';
  description: Scalars['String'];
  template: Scalars['String'];
};

export type NamingTemplateInput = {
  description: Scalars['String'];
  template: Scalars['String'];
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

export enum NotificationFormat {
  Email = 'EMAIL',
  None = 'NONE'
}

export enum NotificationFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY'
}

export type NullableOfTextPositionOperationFilterInput = {
  eq?: InputMaybe<TextPosition>;
  in?: InputMaybe<Array<InputMaybe<TextPosition>>>;
  neq?: InputMaybe<TextPosition>;
  nin?: InputMaybe<Array<InputMaybe<TextPosition>>>;
};

export enum Origin {
  External = 'EXTERNAL',
  Import = 'IMPORT',
  Internal = 'INTERNAL'
}

export type OriginOperationFilterInput = {
  eq?: InputMaybe<Origin>;
  in?: InputMaybe<Array<Origin>>;
  neq?: InputMaybe<Origin>;
  nin?: InputMaybe<Array<Origin>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type ParagraphAttachmentsPayload = {
  __typename?: 'ParagraphAttachmentsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export enum ParagraphElementType {
  HtmlTable = 'HTML_TABLE',
  Image = 'IMAGE',
  NewLine = 'NEW_LINE',
  Table = 'TABLE',
  Text = 'TEXT'
}

export type ParagraphElementTypeOperationFilterInput = {
  eq?: InputMaybe<ParagraphElementType>;
  in?: InputMaybe<Array<ParagraphElementType>>;
  neq?: InputMaybe<ParagraphElementType>;
  nin?: InputMaybe<Array<ParagraphElementType>>;
};

export type ParagraphUpdateInput = {
  paragraphId: Scalars['ID'];
  update: UpdateRegulatoryDocumentParagraphUpdateInput;
};

export type ParentFields = Entity & {
  __typename?: 'ParentFields';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  regulation: RegulationFields;
};

export type PhaseDate = {
  __typename?: 'PhaseDate';
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
};

export type PhaseDateFilterInput = {
  and?: InputMaybe<Array<PhaseDateFilterInput>>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PhaseDateFilterInput>>;
  status?: InputMaybe<StringOperationFilterInput>;
};

export type PhaseDateInput = {
  date?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
};

export type PogisDate = {
  __typename?: 'PogisDate';
  date: Scalars['DateTime'];
  dateType: Scalars['String'];
};

export type PogisDateInput = {
  date: Scalars['DateTime'];
  dateType: Scalars['String'];
};

export type PogisDocument = Entity & NamedEntity & Node & {
  __typename?: 'PogisDocument';
  associations?: Maybe<Array<Scalars['String']>>;
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  dates: Array<PogisDate>;
  docStatus: Scalars['String'];
  editor: Scalars['String'];
  hiddenSearchData?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords: Array<Scalars['String']>;
  linkVersion?: Maybe<Scalars['String']>;
  markets: Array<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  pogisAttachments?: Maybe<Array<AttachmentRef>>;
  pogisId: Scalars['String'];
  regulationStatus: Scalars['String'];
  relatedDocuments?: Maybe<Array<Scalars['String']>>;
  shortInfo: Scalars['String'];
  standards?: Maybe<Array<Scalars['String']>>;
};

export type PogisDocumentAttachmentsPayload = {
  __typename?: 'PogisDocumentAttachmentsPayload';
  pogisDocumentAttachments?: Maybe<AttachmentRef>;
};

export type PogisDocumentPayload = {
  __typename?: 'PogisDocumentPayload';
  pogisDocument: PogisDocument;
};

export type Query = {
  __typename?: 'Query';
  activeTypeMarket?: Maybe<ActiveTypeMarket>;
  activeTypeMarkets?: Maybe<Array<ActiveTypeMarket>>;
  activeTypeMarketsForModelSeriesAndGeneration: Array<ActiveTypeMarket>;
  attachmentCategories?: Maybe<Array<AttachmentCategory>>;
  attachmentCategory?: Maybe<AttachmentCategory>;
  auditLogs?: Maybe<AuditLogsConnection>;
  baseline?: Maybe<Baseline>;
  baselineById?: Maybe<Baseline>;
  baselines?: Maybe<Array<Baseline>>;
  bodyworkVariant?: Maybe<BodyworkVariant>;
  bodyworkVariants?: Maybe<Array<BodyworkVariant>>;
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  compareBaseline: BaselineCompareResult;
  convolute?: Maybe<Convolute>;
  convolutes?: Maybe<Array<Convolute>>;
  countries?: Maybe<Array<Country>>;
  country?: Maybe<Country>;
  documentSource?: Maybe<DocumentSource>;
  documentSources?: Maybe<Array<DocumentSource>>;
  driveVariant?: Maybe<DriveVariant>;
  driveVariants?: Maybe<Array<DriveVariant>>;
  engineVariant?: Maybe<EngineVariant>;
  engineVariants?: Maybe<Array<EngineVariant>>;
  evaluateRegulations: Array<Regulation>;
  evaluateRegulatoryDocumentParagraphs: Array<RegulatoryDocumentParagraph>;
  evaluateRegulatoryDocuments: Array<RegulatoryDocument>;
  evaluateRequirements: Array<Requirement>;
  evaluateVehicleProjects: Array<VehicleProject>;
  gdprRule?: Maybe<GdprRule>;
  gdprRules?: Maybe<Array<GdprRule>>;
  gearboxVariant?: Maybe<GearboxVariant>;
  gearboxVariants?: Maybe<Array<GearboxVariant>>;
  history: GetHistoryOutput;
  keyword?: Maybe<Keyword>;
  keywords?: Maybe<Array<Keyword>>;
  market?: Maybe<Market>;
  markets?: Maybe<Array<Market>>;
  me: User;
  myFavorites: Favorites;
  myKeywords: Array<Keyword>;
  myPendingApprovals: Array<Requirement>;
  mySubscriptions: Array<Subscription>;
  myTableConfigurations: Array<TableConfiguration>;
  myVKOKeywords: Array<Keyword>;
  myVexClusters: Array<VexCluster>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  paragraphs: Array<RegulatoryDocumentParagraph>;
  pdfContentType: GetHistoryOutput;
  pogisDocuments: Array<PogisDocument>;
  reference?: Maybe<Reference>;
  references?: Maybe<Array<Reference>>;
  regulation?: Maybe<Regulation>;
  regulationCluster?: Maybe<RegulationCluster>;
  regulationClusters?: Maybe<Array<RegulationCluster>>;
  regulations: Array<Regulation>;
  regulationsByPage?: Maybe<RegulationsByPageConnection>;
  regulationsByRegulationNumber: Array<Regulation>;
  regulatoryDocument?: Maybe<RegulatoryDocument>;
  regulatoryDocumentBlobReference?: Maybe<BlobRef>;
  regulatoryDocumentParagraph: RegulatoryDocumentParagraph;
  regulatoryDocuments?: Maybe<RegulatoryDocumentsConnection>;
  renameRegulationCusterRefs: RegulatoryDocumentClean;
  requirement?: Maybe<Requirement>;
  requirements: Array<Requirement>;
  requirementsWithAllocation: Array<Requirement>;
  requirementsWithoutAllocation: Array<Requirement>;
  runClean: RegulatoryDocumentClean;
  searchBaseline?: Maybe<Array<Baseline>>;
  searchPogisDocuments: FullTextPogisDocumentResult;
  searchRegulations: FullTextRegulationResult;
  searchRegulatoryDocumentsAndParagraphs: FullTextDocumentResult;
  simulation?: Maybe<Simulation>;
  simulations: Array<Simulation>;
  standardPlusPcmsCluster?: Maybe<StandardPlusPcmsCluster>;
  standardPlusPcmsClusters?: Maybe<Array<StandardPlusPcmsCluster>>;
  systemLevel?: Maybe<SystemLevel>;
  systemLevels?: Maybe<Array<SystemLevel>>;
  tag?: Maybe<Tag>;
  tags?: Maybe<Array<Tag>>;
  user: User;
  userCreatedRegulations: Array<Regulation>;
  users?: Maybe<UsersConnection>;
  vehicleCategories?: Maybe<Array<VehicleCategory>>;
  vehicleCategory?: Maybe<VehicleCategory>;
  vehicleProject?: Maybe<VehicleProject>;
  vehicleProjectBaselines: Array<Baseline>;
  vehicleProjectBaselinesByModelAndSeries: Array<Baseline>;
  vehicleProjectDetailsPageData?: Maybe<VehicleProjectDetailsPageEntity>;
  vehicleProjectOverviewPageData: Array<VehicleProjectOverviewPageEntity>;
  vehicleProjects: Array<VehicleProject>;
  vehicleProjectsByModelSeriesAndGeneration: Array<VehicleProject>;
  vehicleProjectsByModelSeriesGenerationActiveTypeMarket: Array<VehicleProject>;
  vexCluster?: Maybe<VexCluster>;
  vexClusters?: Maybe<Array<VexCluster>>;
  vexRegulatoryDocumentParagraphs: Array<RegulatoryDocumentParagraph>;
  vkoWorkbenchRegulatoryDocumentParagraphs: Array<VkoWorkbenchItem>;
  workbenchRegulatoryDocumentParagraphs: Array<VexWorkbenchItem>;
  workbenchRegulatoryDocuments: Array<RegulatoryDocument>;
};


export type QueryActiveTypeMarketArgs = {
  id: Scalars['ID'];
};


export type QueryActiveTypeMarketsArgs = {
  order?: InputMaybe<Array<ActiveTypeMarketSortInput>>;
};


export type QueryActiveTypeMarketsForModelSeriesAndGenerationArgs = {
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
};


export type QueryAttachmentCategoriesArgs = {
  order?: InputMaybe<Array<AttachmentCategorySortInput>>;
};


export type QueryAttachmentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryAuditLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AuditLogSortInput>>;
  where?: InputMaybe<AuditLogFilterInput>;
};


export type QueryBaselineArgs = {
  id: Scalars['ID'];
};


export type QueryBaselineByIdArgs = {
  id: Scalars['ID'];
};


export type QueryBodyworkVariantArgs = {
  id: Scalars['ID'];
};


export type QueryBodyworkVariantsArgs = {
  order?: InputMaybe<Array<BodyworkVariantSortInput>>;
};


export type QueryCategoriesArgs = {
  order?: InputMaybe<Array<CategorySortInput>>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCompareBaselineArgs = {
  baselineId1?: InputMaybe<Scalars['ID']>;
  baselineId2?: InputMaybe<Scalars['ID']>;
};


export type QueryConvoluteArgs = {
  id: Scalars['ID'];
};


export type QueryConvolutesArgs = {
  order?: InputMaybe<Array<ConvoluteSortInput>>;
};


export type QueryCountriesArgs = {
  order?: InputMaybe<Array<CountrySortInput>>;
};


export type QueryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentSourceArgs = {
  id: Scalars['ID'];
};


export type QueryDriveVariantArgs = {
  id: Scalars['ID'];
};


export type QueryDriveVariantsArgs = {
  order?: InputMaybe<Array<DriveVariantSortInput>>;
};


export type QueryEngineVariantArgs = {
  id: Scalars['ID'];
};


export type QueryEngineVariantsArgs = {
  order?: InputMaybe<Array<EngineVariantSortInput>>;
};


export type QueryEvaluateRegulationsArgs = {
  personId: Scalars['ID'];
};


export type QueryEvaluateRegulatoryDocumentParagraphsArgs = {
  personId: Scalars['ID'];
};


export type QueryEvaluateRegulatoryDocumentsArgs = {
  personId: Scalars['ID'];
};


export type QueryEvaluateRequirementsArgs = {
  personId: Scalars['ID'];
};


export type QueryEvaluateVehicleProjectsArgs = {
  personId: Scalars['ID'];
};


export type QueryGdprRuleArgs = {
  id: Scalars['ID'];
};


export type QueryGearboxVariantArgs = {
  id: Scalars['ID'];
};


export type QueryGearboxVariantsArgs = {
  order?: InputMaybe<Array<GearboxVariantSortInput>>;
};


export type QueryHistoryArgs = {
  batchIndex: Scalars['Int'];
  batchSize: Scalars['Int'];
};


export type QueryKeywordArgs = {
  id: Scalars['ID'];
};


export type QueryKeywordsArgs = {
  order?: InputMaybe<Array<KeywordSortInput>>;
};


export type QueryMarketArgs = {
  id: Scalars['ID'];
};


export type QueryMarketsArgs = {
  order?: InputMaybe<Array<MarketSortInput>>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryParagraphsArgs = {
  paragraphIds: Array<Scalars['String']>;
};


export type QueryPdfContentTypeArgs = {
  batchIndex: Scalars['Int'];
  batchSize: Scalars['Int'];
};


export type QueryReferenceArgs = {
  id: Scalars['ID'];
};


export type QueryReferencesArgs = {
  order?: InputMaybe<Array<ReferenceSortInput>>;
};


export type QueryRegulationArgs = {
  id: Scalars['ID'];
};


export type QueryRegulationClusterArgs = {
  id: Scalars['ID'];
};


export type QueryRegulationClustersArgs = {
  order?: InputMaybe<Array<RegulationClusterSortInput>>;
};


export type QueryRegulationsByPageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRegulationsByRegulationNumberArgs = {
  regulationNumber: Scalars['String'];
};


export type QueryRegulatoryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryRegulatoryDocumentBlobReferenceArgs = {
  id: Scalars['ID'];
};


export type QueryRegulatoryDocumentParagraphArgs = {
  paragraphId: Scalars['ID'];
  regulatoryDocumentId: Scalars['ID'];
};


export type QueryRegulatoryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<RegulatoryDocumentSortInput>>;
  where?: InputMaybe<RegulatoryDocumentFilterInput>;
};


export type QueryRenameRegulationCusterRefsArgs = {
  batchIndex: Scalars['Int'];
  batchSize: Scalars['Int'];
};


export type QueryRequirementArgs = {
  id: Scalars['ID'];
};


export type QueryRunCleanArgs = {
  batchIndex: Scalars['Int'];
  batchSize: Scalars['Int'];
};


export type QuerySearchBaselineArgs = {
  order?: InputMaybe<Array<QueryableScope_BaselineSortInput>>;
  projectName?: InputMaybe<Scalars['String']>;
};


export type QuerySearchPogisDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchString: Scalars['String'];
};


export type QuerySearchRegulationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchString: Scalars['String'];
};


export type QuerySearchRegulatoryDocumentsAndParagraphsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchString: Scalars['String'];
};


export type QuerySimulationArgs = {
  id: Scalars['ID'];
};


export type QueryStandardPlusPcmsClusterArgs = {
  id: Scalars['ID'];
};


export type QueryStandardPlusPcmsClustersArgs = {
  order?: InputMaybe<Array<StandardPlusPcmsClusterSortInput>>;
};


export type QuerySystemLevelArgs = {
  id: Scalars['ID'];
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserCreatedRegulationsArgs = {
  userId: Scalars['String'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<QueryableScope_UserSortInput>>;
  role?: InputMaybe<UserRole>;
  searchString: Scalars['String'];
};


export type QueryVehicleCategoriesArgs = {
  order?: InputMaybe<Array<VehicleCategorySortInput>>;
};


export type QueryVehicleCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryVehicleProjectArgs = {
  id: Scalars['ID'];
};


export type QueryVehicleProjectBaselinesArgs = {
  vehicleProjectId: Scalars['String'];
};


export type QueryVehicleProjectBaselinesByModelAndSeriesArgs = {
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
};


export type QueryVehicleProjectDetailsPageDataArgs = {
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
};


export type QueryVehicleProjectsArgs = {
  where?: InputMaybe<VehicleProjectFilterInput>;
};


export type QueryVehicleProjectsByModelSeriesAndGenerationArgs = {
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
};


export type QueryVehicleProjectsByModelSeriesGenerationActiveTypeMarketArgs = {
  activeTypeMarketId: Scalars['ID'];
  generation: Scalars['String'];
  modelSeries: Scalars['String'];
};


export type QueryVexClusterArgs = {
  id: Scalars['ID'];
};


export type QueryVexClustersArgs = {
  order?: InputMaybe<Array<VexClusterSortInput>>;
  where?: InputMaybe<VexClusterFilterInput>;
};


export type QueryVexRegulatoryDocumentParagraphsArgs = {
  inProcess: Scalars['Boolean'];
  regulatoryDocumentId: Scalars['ID'];
  where?: InputMaybe<RegulatoryDocumentFilterInput>;
};


export type QueryWorkbenchRegulatoryDocumentParagraphsArgs = {
  inProcess: Scalars['Boolean'];
  where?: InputMaybe<RegulatoryDocumentFilterInput>;
};


export type QueryWorkbenchRegulatoryDocumentsArgs = {
  where?: InputMaybe<RegulatoryDocumentFilterInput>;
};

export type QueryableScope_BaselineSortInput = {
  createdAt?: InputMaybe<QueryableScope_SortEnumType>;
  createdAtUtcDays?: InputMaybe<QueryableScope_SortEnumType>;
  createdAtUtcSeconds?: InputMaybe<QueryableScope_SortEnumType>;
  id?: InputMaybe<QueryableScope_SortEnumType>;
  isCurrent?: InputMaybe<QueryableScope_SortEnumType>;
  modifiedAt?: InputMaybe<QueryableScope_SortEnumType>;
  name?: InputMaybe<QueryableScope_SortEnumType>;
  vehiclePorjectName?: InputMaybe<QueryableScope_SortEnumType>;
};

export enum QueryableScope_SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type QueryableScope_UserSortInput = {
  givenName?: InputMaybe<QueryableScope_SortEnumType>;
  id?: InputMaybe<QueryableScope_SortEnumType>;
  mail?: InputMaybe<QueryableScope_SortEnumType>;
  name?: InputMaybe<QueryableScope_SortEnumType>;
  surname?: InputMaybe<QueryableScope_SortEnumType>;
  userPrincipalName?: InputMaybe<QueryableScope_SortEnumType>;
};

export type ReassignAllRegulatoryDocumentsForClonedRequirementInput = {
  distRequirementId: Scalars['ID'];
  sourceRequirementId: Scalars['ID'];
};

export type ReassignAllRegulatoryDocumentsForClonedRequirementPayload = {
  __typename?: 'ReassignAllRegulatoryDocumentsForClonedRequirementPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type Reference = Entity & NamedEntity & Node & {
  __typename?: 'Reference';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  referenceTypes: Array<ReferenceType>;
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type ReferenceNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type ReferenceSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum ReferenceType {
  Paragraph = 'PARAGRAPH',
  Regulation = 'REGULATION',
  RegulatoryDocument = 'REGULATORY_DOCUMENT',
  Requirement = 'REQUIREMENT'
}

export type Regulation = Entity & NamedEntity & Node & {
  __typename?: 'Regulation';
  anonymous: Scalars['Boolean'];
  attachments?: Maybe<Array<AttachmentRef>>;
  auditLog: Array<AuditLog>;
  comments?: Maybe<Array<Commentary>>;
  contactPersons: Array<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deletionPeriod: Scalars['Int'];
  driveVariants: Array<DriveVariant>;
  faqs?: Maybe<Array<Faq>>;
  id: Scalars['ID'];
  keywords: Array<Keyword>;
  mainKeywords: Array<Keyword>;
  markets: Array<Market>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  regulationClusters: Array<RegulationCluster>;
  regulationNumber: Scalars['String'];
  regulatoryDocuments: Array<RegulatoryDocument>;
  standardPlusPcmsClusters: Array<StandardPlusPcmsCluster>;
  summary: Scalars['String'];
  vehicleCategories: Array<VehicleCategory>;
  willBeAnonymized: Scalars['Boolean'];
};


export type RegulationRegulatoryDocumentsArgs = {
  order?: InputMaybe<Array<RegulatoryDocumentSortInput>>;
  where?: InputMaybe<RegulatoryDocumentFilterInput>;
};

export type RegulationAttachmentsPayload = {
  __typename?: 'RegulationAttachmentsPayload';
  regulationAttachments?: Maybe<UpdateEntityAttachmentsResult>;
};

export type RegulationCluster = Entity & NamedEntity & Node & {
  __typename?: 'RegulationCluster';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type RegulationClusterSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type RegulationDetailsPage = Entity & {
  __typename?: 'RegulationDetailsPage';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  keywords: Array<Keyword>;
  mainKeywords: Array<Keyword>;
  markets: Array<Market>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  regulationNumber: Scalars['String'];
  standardPlusPcmsClusters: Array<StandardPlusPcmsCluster>;
};

export type RegulationFields = Entity & {
  __typename?: 'RegulationFields';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  regulationNumber: Scalars['String'];
};

export type RegulationPayload = {
  __typename?: 'RegulationPayload';
  regulation: Regulation;
};

/** A connection to a list of items. */
export type RegulationsByPageConnection = {
  __typename?: 'RegulationsByPageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RegulationsByPageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Regulation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RegulationsByPageEdge = {
  __typename?: 'RegulationsByPageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Regulation;
};

export type RegulatoryDocument = Entity & NamedEntity & Node & {
  __typename?: 'RegulatoryDocument';
  anonymous: Scalars['Boolean'];
  attachments?: Maybe<Array<AttachmentRef>>;
  childrenRefs?: Maybe<Array<RegulatoryDocument>>;
  clearingAuditLogs: Array<AuditLog>;
  comments?: Maybe<Array<Commentary>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  dateEffective?: Maybe<Scalars['Date']>;
  dateExpiration?: Maybe<Scalars['Date']>;
  dateNewRegistration?: Maybe<Scalars['Date']>;
  dateNewTypes?: Maybe<Scalars['Date']>;
  deletionPeriod: Scalars['Int'];
  document?: Maybe<BlobRef>;
  documentError?: Maybe<Scalars['String']>;
  documentReferences?: Maybe<Array<DocumentReference>>;
  documentSource?: Maybe<DocumentSource>;
  documentStatus: DocumentStatus;
  driveVariants: Array<DriveVariant>;
  faqs?: Maybe<Array<Faq>>;
  finalVersionRef?: Maybe<RegulatoryDocument>;
  hash?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords: Array<Keyword>;
  modelYear?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  origin: Origin;
  paragraphs: Array<RegulatoryDocumentParagraph>;
  phaseIn: Array<PhaseDate>;
  phaseOut: Array<PhaseDate>;
  predecessor?: Maybe<RegulatoryDocument>;
  regulation?: Maybe<Regulation>;
  serviceProviders: Array<User>;
  status: RegulatoryDocumentStatus;
  successor?: Maybe<RegulatoryDocument>;
  summary?: Maybe<Scalars['String']>;
  topicManagementSummary?: Maybe<TopicManagementSummary>;
  vehicleCategories: Array<VehicleCategory>;
  willBeAnonymized: Scalars['Boolean'];
  workflow: Workflow;
};

export type RegulatoryDocumentAssetPayload = {
  __typename?: 'RegulatoryDocumentAssetPayload';
  regulatoryDocumentAsset?: Maybe<BlobRef>;
};

export type RegulatoryDocumentAttachmentsPayload = {
  __typename?: 'RegulatoryDocumentAttachmentsPayload';
  regulatoryDocumentAttachments?: Maybe<UpdateEntityAttachmentsResult>;
};

export type RegulatoryDocumentClean = Entity & {
  __typename?: 'RegulatoryDocumentClean';
  attachments?: Maybe<Array<AttachmentRef>>;
  batchIndex: Scalars['Int'];
  batchSize: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  regulatoryDocumentsName: Array<Scalars['String']>;
  totalCount: Scalars['Int'];
  updatedDocumentIndex: Array<Scalars['Int']>;
};

export type RegulatoryDocumentFilePayload = {
  __typename?: 'RegulatoryDocumentFilePayload';
  errors?: Maybe<Array<UploadRegulatoryDocumentFileError>>;
  regulatoryDocumentFile?: Maybe<BlobRef>;
};

export enum RegulatoryDocumentFileType {
  Pdf = 'PDF',
  ReqIf = 'REQ_IF'
}

export type RegulatoryDocumentFilterInput = {
  and?: InputMaybe<Array<RegulatoryDocumentFilterInput>>;
  anonymous?: InputMaybe<BooleanOperationFilterInput>;
  comments?: InputMaybe<ListFilterInputTypeOfCommentaryFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  dateEffective?: InputMaybe<DateTimeOperationFilterInput>;
  dateExpiration?: InputMaybe<DateTimeOperationFilterInput>;
  dateNewRegistration?: InputMaybe<DateTimeOperationFilterInput>;
  dateNewTypes?: InputMaybe<DateTimeOperationFilterInput>;
  deletionPeriod?: InputMaybe<IntOperationFilterInput>;
  documentError?: InputMaybe<StringOperationFilterInput>;
  documentReferences?: InputMaybe<ListFilterInputTypeOfDocumentReferenceFilterInput>;
  documentStatus?: InputMaybe<DocumentStatusOperationFilterInput>;
  faqs?: InputMaybe<ListFilterInputTypeOfFaqFilterInput>;
  hash?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  modelYear?: InputMaybe<IntOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedById?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RegulatoryDocumentFilterInput>>;
  origin?: InputMaybe<OriginOperationFilterInput>;
  paragraphs?: InputMaybe<ListFilterInputTypeOfRegulatoryDocumentParagraphFilterInput>;
  phaseIn?: InputMaybe<ListFilterInputTypeOfPhaseDateFilterInput>;
  phaseOut?: InputMaybe<ListFilterInputTypeOfPhaseDateFilterInput>;
  regulationRef?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<RegulatoryDocumentStatusOperationFilterInput>;
  summary?: InputMaybe<StringOperationFilterInput>;
  topicManagementSummary?: InputMaybe<TopicManagementSummaryFilterInput>;
  willBeAnonymized?: InputMaybe<BooleanOperationFilterInput>;
  workflow?: InputMaybe<WorkflowFilterInput>;
};

export type RegulatoryDocumentParagraph = Entity & {
  __typename?: 'RegulatoryDocumentParagraph';
  attachments?: Maybe<Array<AttachmentRef>>;
  auditLog: Array<AuditLog>;
  categories: Array<Category>;
  comments?: Maybe<Array<Commentary>>;
  comprehensive?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  dateNewRegistration?: Maybe<Scalars['Date']>;
  dateNewTypes?: Maybe<Scalars['Date']>;
  driveVariants: Array<DriveVariant>;
  elements: Array<RegulatoryDocumentParagraphElement>;
  enumeration: Scalars['String'];
  faqs?: Maybe<Array<Faq>>;
  id: Scalars['ID'];
  isFootnote: Scalars['Boolean'];
  keywordAssignments?: Maybe<Array<KeywordAssignment>>;
  keywords: Array<Keyword>;
  level: Scalars['Int'];
  modelYear?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  page: Scalars['Int'];
  parent: RegulatoryDocument;
  phaseIn?: Maybe<Array<PhaseDate>>;
  phaseOut?: Maybe<Array<PhaseDate>>;
  requirements: Array<Requirement>;
  summary?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  vehicleCategories: Array<VehicleCategory>;
};

export type RegulatoryDocumentParagraphDetailsPage = Entity & {
  __typename?: 'RegulatoryDocumentParagraphDetailsPage';
  attachments?: Maybe<Array<AttachmentRef>>;
  categories: Array<Category>;
  comprehensive?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  dateNewRegistration?: Maybe<Scalars['DateTime']>;
  dateNewTypes?: Maybe<Scalars['DateTime']>;
  driveVariants: Array<DriveVariantFields>;
  elements: Array<RegulatoryDocumentParagraphElement>;
  enumeration: Scalars['String'];
  id: Scalars['ID'];
  isFootnote: Scalars['Boolean'];
  keywordAssignments?: Maybe<Array<KeywordAssignment>>;
  keywords: Array<Keyword>;
  level: Scalars['Int'];
  modelYear?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  page?: Maybe<Scalars['Int']>;
  parent: ParentFields;
  phaseIn?: Maybe<Array<PhaseDate>>;
  phaseOut?: Maybe<Array<PhaseDate>>;
  requirements: Array<Requirement>;
  summary?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  vehicleCategories: Array<VehicleCategory>;
};

export type RegulatoryDocumentParagraphElement = {
  __typename?: 'RegulatoryDocumentParagraphElement';
  asset?: Maybe<BlobRef>;
  assetId?: Maybe<Scalars['String']>;
  bounds?: Maybe<Array<Scalars['Float']>>;
  csvContent?: Maybe<Scalars['String']>;
  isHeader: Scalars['Boolean'];
  page: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  textPosition?: Maybe<TextPosition>;
  type: ParagraphElementType;
};

export type RegulatoryDocumentParagraphElementFilterInput = {
  and?: InputMaybe<Array<RegulatoryDocumentParagraphElementFilterInput>>;
  assetId?: InputMaybe<StringOperationFilterInput>;
  bounds?: InputMaybe<ListFloatOperationFilterInput>;
  csvContent?: InputMaybe<StringOperationFilterInput>;
  isHeader?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RegulatoryDocumentParagraphElementFilterInput>>;
  page?: InputMaybe<IntOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  textPosition?: InputMaybe<NullableOfTextPositionOperationFilterInput>;
  type?: InputMaybe<ParagraphElementTypeOperationFilterInput>;
};

export type RegulatoryDocumentParagraphElementInput = {
  assetId?: InputMaybe<Scalars['String']>;
  bounds?: InputMaybe<Array<Scalars['Float']>>;
  csvContent?: InputMaybe<Scalars['String']>;
  isHeader: Scalars['Boolean'];
  page: Scalars['Int'];
  text?: InputMaybe<Scalars['String']>;
  textPosition?: InputMaybe<TextPosition>;
  type: ParagraphElementType;
};

export type RegulatoryDocumentParagraphFilterInput = {
  and?: InputMaybe<Array<RegulatoryDocumentParagraphFilterInput>>;
  comments?: InputMaybe<ListFilterInputTypeOfCommentaryFilterInput>;
  comprehensive?: InputMaybe<DateTimeOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  dateNewRegistration?: InputMaybe<DateTimeOperationFilterInput>;
  dateNewTypes?: InputMaybe<DateTimeOperationFilterInput>;
  elements?: InputMaybe<ListFilterInputTypeOfRegulatoryDocumentParagraphElementFilterInput>;
  enumeration?: InputMaybe<StringOperationFilterInput>;
  faqs?: InputMaybe<ListFilterInputTypeOfFaqFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  isFootnote?: InputMaybe<BooleanOperationFilterInput>;
  keywordAssignments?: InputMaybe<ListFilterInputTypeOfKeywordAssignmentFilterInput>;
  level?: InputMaybe<IntOperationFilterInput>;
  modelYear?: InputMaybe<IntOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RegulatoryDocumentParagraphFilterInput>>;
  page?: InputMaybe<IntOperationFilterInput>;
  parent?: InputMaybe<RegulatoryDocumentFilterInput>;
  phaseIn?: InputMaybe<ListFilterInputTypeOfPhaseDateFilterInput>;
  phaseOut?: InputMaybe<ListFilterInputTypeOfPhaseDateFilterInput>;
  summary?: InputMaybe<StringOperationFilterInput>;
};

export type RegulatoryDocumentPayload = {
  __typename?: 'RegulatoryDocumentPayload';
  regulatoryDocument: RegulatoryDocument;
};

export type RegulatoryDocumentSortInput = {
  anonymous?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  dateEffective?: InputMaybe<SortEnumType>;
  dateExpiration?: InputMaybe<SortEnumType>;
  dateNewRegistration?: InputMaybe<SortEnumType>;
  dateNewTypes?: InputMaybe<SortEnumType>;
  deletionPeriod?: InputMaybe<SortEnumType>;
  documentError?: InputMaybe<SortEnumType>;
  documentStatus?: InputMaybe<SortEnumType>;
  hash?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modelYear?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  summary?: InputMaybe<SortEnumType>;
  topicManagementSummary?: InputMaybe<TopicManagementSummarySortInput>;
  willBeAnonymized?: InputMaybe<SortEnumType>;
  workflow?: InputMaybe<WorkflowSortInput>;
};

export enum RegulatoryDocumentStatus {
  Consideration = 'CONSIDERATION',
  Discarded = 'DISCARDED',
  Draft = 'DRAFT',
  Final = 'FINAL',
  Interpretation = 'INTERPRETATION'
}

export type RegulatoryDocumentStatusOperationFilterInput = {
  eq?: InputMaybe<RegulatoryDocumentStatus>;
  in?: InputMaybe<Array<RegulatoryDocumentStatus>>;
  neq?: InputMaybe<RegulatoryDocumentStatus>;
  nin?: InputMaybe<Array<RegulatoryDocumentStatus>>;
};

/** A connection to a list of items. */
export type RegulatoryDocumentsConnection = {
  __typename?: 'RegulatoryDocumentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RegulatoryDocumentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RegulatoryDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RegulatoryDocumentsEdge = {
  __typename?: 'RegulatoryDocumentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RegulatoryDocument;
};

export enum RejectReasonOption {
  NoDerivation = 'NO_DERIVATION',
  WrongKeyword = 'WRONG_KEYWORD'
}

export type RejectReasonOptionOperationFilterInput = {
  eq?: InputMaybe<RejectReasonOption>;
  in?: InputMaybe<Array<RejectReasonOption>>;
  neq?: InputMaybe<RejectReasonOption>;
  nin?: InputMaybe<Array<RejectReasonOption>>;
};

export type RemoveRegulatoryDocumentParagraphsRequirementsInput = {
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
  requirementRefs: Array<EntityRefOfRequirementInput>;
};

export type RemoveRegulatoryDocumentParagraphsRequirementsPayload = {
  __typename?: 'RemoveRegulatoryDocumentParagraphsRequirementsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type RemoveRequirementsBatchInput = {
  removeReason: Scalars['String'];
  requirementIds: Array<Scalars['ID']>;
  vehicleProjectIds: Array<Scalars['ID']>;
};

export type RemoveRequirementsBatchPayload = {
  __typename?: 'RemoveRequirementsBatchPayload';
  vehicleProject?: Maybe<Array<VehicleProject>>;
};

export type RemoveRequirementsInput = {
  removeReason: Scalars['String'];
  requirementIds: Array<Scalars['ID']>;
  vehicleProjectId: Scalars['ID'];
};

export type RemoveRequirementsPayload = {
  __typename?: 'RemoveRequirementsPayload';
  vehicleProject?: Maybe<VehicleProject>;
};

export type RemoveVehicleProjectPermissionsInput = {
  modelSeries: Scalars['String'];
  user: Scalars['ID'];
};

export type RemoveVehicleProjectPermissionsPayload = {
  __typename?: 'RemoveVehicleProjectPermissionsPayload';
  user?: Maybe<User>;
};

export type Requirement = Entity & NamedEntity & Node & {
  __typename?: 'Requirement';
  activeTypeMarkets: Array<ActiveTypeMarket>;
  aggregatedFields: AggregatedFields;
  anonymous: Scalars['Boolean'];
  approvalWorkFlow: ApprovalWorkFlow;
  assignedParagraphs: Array<RegulatoryDocumentParagraph>;
  attachments?: Maybe<Array<AttachmentRef>>;
  auditLog: Array<AuditLog>;
  bodyworkVariants: Array<BodyworkVariant>;
  category: RequirementCategory;
  changeType: RequirementChangeType;
  childrenRefs?: Maybe<Array<Requirement>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  definition?: Maybe<Scalars['String']>;
  deletionPeriod: Scalars['Int'];
  documentReferences?: Maybe<Array<DocumentReference>>;
  driveVariants: Array<DriveVariant>;
  engineVariants: Array<EngineVariant>;
  gearboxVariants: Array<GearboxVariant>;
  id: Scalars['ID'];
  keywords: Array<Keyword>;
  markets: Array<Market>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  parentRef?: Maybe<Requirement>;
  requirementId?: Maybe<Scalars['String']>;
  status: RequirementStatus;
  successor?: Maybe<Requirement>;
  systemLevelRefs: Array<EntityRefOfSystemLevel>;
  systemLevels: Array<SystemLevel>;
  tags: Array<Tag>;
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
  vehicleCategories: Array<VehicleCategory>;
  version?: Maybe<Scalars['String']>;
  vexClusters: Array<VexCluster>;
  willBeAnonymized: Scalars['Boolean'];
};


export type RequirementNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type RequirementAttachmentsPayload = {
  __typename?: 'RequirementAttachmentsPayload';
  requirementAttachments?: Maybe<UpdateEntityAttachmentsResult>;
};

export enum RequirementCategory {
  ProcessRequirement = 'PROCESS_REQUIREMENT',
  ProductRequirement = 'PRODUCT_REQUIREMENT',
  TestRequirement = 'TEST_REQUIREMENT'
}

export enum RequirementChangeType {
  Content = 'CONTENT',
  Editorial = 'EDITORIAL',
  None = 'NONE'
}

export type RequirementInput = {
  anonymous: Scalars['Boolean'];
  approvalWorkFlow: ApprovalWorkFlowInput;
  category: RequirementCategory;
  changeType: RequirementChangeType;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  definition?: InputMaybe<Scalars['String']>;
  deletionPeriod: Scalars['Int'];
  documentReferences?: InputMaybe<Array<DocumentReferenceInput>>;
  id: Scalars['ID'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  requirementId?: InputMaybe<Scalars['String']>;
  status: RequirementStatus;
  systemLevelRefs: Array<EntityRefOfSystemLevelInput>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  version?: InputMaybe<Scalars['String']>;
  willBeAnonymized: Scalars['Boolean'];
};

export type RequirementPayload = {
  __typename?: 'RequirementPayload';
  requirement: Requirement;
};

export enum RequirementStatus {
  Approval = 'APPROVAL',
  Draft = 'DRAFT',
  Final = 'FINAL',
  Interim = 'INTERIM',
  InterimCompleted = 'INTERIM_COMPLETED',
  Modified = 'MODIFIED'
}

export type Simulation = Entity & NamedEntity & Node & {
  __typename?: 'Simulation';
  assignedRequirements: Array<Requirement>;
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  derivatives: Array<Scalars['String']>;
  earliestSOP?: Maybe<Scalars['DateTime']>;
  generationReference?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  markets: Array<Market>;
  modelSeriesReference?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  paragraphs: Array<RegulatoryDocumentParagraph>;
  regulations: Array<Regulation>;
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
  vehicleProjects: Array<SimulationVehicleProject>;
};


export type SimulationNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type SimulationPayload = {
  __typename?: 'SimulationPayload';
  simulation: Simulation;
};

export type SimulationVehicleProject = Entity & NamedEntity & Node & {
  __typename?: 'SimulationVehicleProject';
  activeTypeMarkets: Array<ActiveTypeMarket>;
  anonymous: Scalars['Boolean'];
  assignedRequirements: Array<AssignedRequirement>;
  attachments?: Maybe<Array<AttachmentRef>>;
  auditLog: Array<AuditLog>;
  bodyworkVariants: Array<BodyworkVariant>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deletionPeriod: Scalars['Int'];
  derivative?: Maybe<Scalars['String']>;
  detVsi?: Maybe<Scalars['Date']>;
  driveVariants: Array<DriveVariant>;
  engineVariants: Array<EngineVariant>;
  eopVsi?: Maybe<Scalars['Date']>;
  euLegislation?: Maybe<Scalars['Boolean']>;
  gearboxVariants: Array<GearboxVariant>;
  generation: Scalars['String'];
  id: Scalars['ID'];
  isElectro?: Maybe<Scalars['Boolean']>;
  markets: Array<Market>;
  modelSeries: Scalars['String'];
  modelYear?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  p10Vsi?: Maybe<Scalars['Date']>;
  posEtVsi?: Maybe<Scalars['Date']>;
  posVsi?: Maybe<Scalars['Date']>;
  sopVsi?: Maybe<Scalars['Date']>;
  sosVsi?: Maybe<Scalars['Date']>;
  sysgenVsi?: Maybe<Scalars['Date']>;
  typStartVsi?: Maybe<Scalars['Date']>;
  usersWithPermissions: Array<User>;
  willBeAnonymized: Scalars['Boolean'];
  wvtaVsi?: Maybe<Scalars['Date']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StandardPlusPcmsCluster = Entity & NamedEntity & Node & {
  __typename?: 'StandardPlusPcmsCluster';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type StandardPlusPcmsClusterSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = Entity & NamedEntity & Node & {
  __typename?: 'Subscription';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  keywordRefs: Array<EntityRefOfKeyword>;
  keywords: Array<Keyword>;
  marketRefs: Array<EntityRefOfMarket>;
  markets: Array<Market>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  notificationFormat: NotificationFormat;
  notificationFrequency: NotificationFrequency;
  optOnlyForMainKeywords?: Maybe<Scalars['Boolean']>;
  standardPlusPcmsClusterRefs: Array<EntityRefOfStandardPlusPcmsCluster>;
  standardPlusPcmsClusters: Array<StandardPlusPcmsCluster>;
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  subscription: Subscription;
};

export type SyncNewVexClustersInput = {
  keywordId: Scalars['ID'];
  newVexClusterIds: Array<Scalars['ID']>;
};

export type SyncNewVexClustersPayload = {
  __typename?: 'SyncNewVexClustersPayload';
  taskId: Scalars['String'];
};

export type SystemLevel = Entity & NamedEntity & Node & {
  __typename?: 'SystemLevel';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type SystemLevelNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type TableConfiguration = {
  __typename?: 'TableConfiguration';
  columns: Array<Column>;
  tableId: Scalars['String'];
};

export type Tag = Entity & NamedEntity & Node & {
  __typename?: 'Tag';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  translations?: Maybe<Array<KeyValuePairOfStringAndString>>;
};


export type TagNameArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export enum TextPosition {
  Subscript = 'SUBSCRIPT',
  Superscript = 'SUPERSCRIPT'
}

export type TopicManagementSummary = {
  __typename?: 'TopicManagementSummary';
  quarter: TopicManagementSummaryQuarter;
  year: Scalars['Int'];
};

export type TopicManagementSummaryFilterInput = {
  and?: InputMaybe<Array<TopicManagementSummaryFilterInput>>;
  or?: InputMaybe<Array<TopicManagementSummaryFilterInput>>;
  quarter?: InputMaybe<TopicManagementSummaryQuarterOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type TopicManagementSummaryInput = {
  quarter: TopicManagementSummaryQuarter;
  year: Scalars['Int'];
};

export enum TopicManagementSummaryQuarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type TopicManagementSummaryQuarterOperationFilterInput = {
  eq?: InputMaybe<TopicManagementSummaryQuarter>;
  in?: InputMaybe<Array<TopicManagementSummaryQuarter>>;
  neq?: InputMaybe<TopicManagementSummaryQuarter>;
  nin?: InputMaybe<Array<TopicManagementSummaryQuarter>>;
};

export type TopicManagementSummarySortInput = {
  quarter?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type UpdateActiveTypeMarketInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateActiveTypeMarketPayload = {
  __typename?: 'UpdateActiveTypeMarketPayload';
  activeTypeMarket?: Maybe<ActiveTypeMarket>;
};

export type UpdateAttachmentCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateAttachmentCategoryPayload = {
  __typename?: 'UpdateAttachmentCategoryPayload';
  attachmentCategory?: Maybe<AttachmentCategory>;
};

export type UpdateBaselineInput = {
  createdAtUtcDays: Scalars['Long'];
  createdAtUtcSeconds: Scalars['Long'];
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  name: Scalars['String'];
  requirements: Array<BaselineRequirementInput>;
  vehiclePorjectName: Scalars['String'];
};

export type UpdateBaselinePayload = {
  __typename?: 'UpdateBaselinePayload';
  baseline?: Maybe<Baseline>;
};

export type UpdateBodyworkVariantInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateBodyworkVariantPayload = {
  __typename?: 'UpdateBodyworkVariantPayload';
  bodyworkVariant?: Maybe<BodyworkVariant>;
};

export type UpdateCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
};

export type UpdateConvoluteInput = {
  convoluteType: ConvoluteType;
  gdprRuleRef?: InputMaybe<EntityRefOfGdprRuleInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateConvolutePayload = {
  __typename?: 'UpdateConvolutePayload';
  convolute?: Maybe<Convolute>;
};

export type UpdateCountryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateCountryPayload = {
  __typename?: 'UpdateCountryPayload';
  country?: Maybe<Country>;
};

export type UpdateDocumentSourceInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateDocumentSourcePayload = {
  __typename?: 'UpdateDocumentSourcePayload';
  documentSource?: Maybe<DocumentSource>;
};

export type UpdateDriveVariantInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateDriveVariantPayload = {
  __typename?: 'UpdateDriveVariantPayload';
  driveVariant?: Maybe<DriveVariant>;
};

export type UpdateEngineVariantInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateEngineVariantPayload = {
  __typename?: 'UpdateEngineVariantPayload';
  engineVariant?: Maybe<EngineVariant>;
};

export type UpdateEntityAttachmentsResult = {
  __typename?: 'UpdateEntityAttachmentsResult';
  added: Scalars['Int'];
  deleted: Scalars['Int'];
};

export type UpdateGdprRuleInput = {
  anonymize: Scalars['Boolean'];
  deletionPeriod: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateGdprRulePayload = {
  __typename?: 'UpdateGdprRulePayload';
  gdprRule?: Maybe<GdprRule>;
};

export type UpdateGearboxVariantInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateGearboxVariantPayload = {
  __typename?: 'UpdateGearboxVariantPayload';
  gearboxVariant?: Maybe<GearboxVariant>;
};

export type UpdateKeywordInput = {
  approverRefs: Array<EntityRefOfUserInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  regulationClusterRefs: Array<EntityRefOfRegulationClusterInput>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  vexClusterRefs: Array<EntityRefOfVexClusterInput>;
  vkoRefs: Array<EntityRefOfVkoInput>;
};

export type UpdateKeywordPayload = {
  __typename?: 'UpdateKeywordPayload';
  keyword?: Maybe<Keyword>;
};

export type UpdateMarketInput = {
  countriesRef: Array<EntityRefOfCountryInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  regulationNamingTemplates: Array<NamingTemplateInput>;
  regulatoryDocumentNamingTemplates: Array<NamingTemplateInput>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateMarketPayload = {
  __typename?: 'UpdateMarketPayload';
  market?: Maybe<Market>;
};

export type UpdateParagraphAttachmentsInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
};

export type UpdatePogisDocumentAssetInput = {
  attachment: AttachmentInput;
  pogisDocumentId: Scalars['ID'];
};

export type UpdatePogisDocumentInput = {
  associations?: InputMaybe<Array<Scalars['String']>>;
  dates: Array<PogisDateInput>;
  docStatus: Scalars['String'];
  editor: Scalars['String'];
  id: Scalars['ID'];
  keywords: Array<Scalars['String']>;
  linkVersion?: InputMaybe<Scalars['String']>;
  markets: Array<Scalars['String']>;
  pogisId: Scalars['String'];
  regulationStatus: Scalars['String'];
  relatedDocuments?: InputMaybe<Array<Scalars['String']>>;
  shortInfo: Scalars['String'];
  standards?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateReferenceInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  referenceTypes: Array<ReferenceType>;
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateReferencePayload = {
  __typename?: 'UpdateReferencePayload';
  reference?: Maybe<Reference>;
};

export type UpdateRegulationAttachmentsInput = {
  attachments: Array<AttachmentInput>;
  regulationId: Scalars['ID'];
};

export type UpdateRegulationClusterInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateRegulationClusterPayload = {
  __typename?: 'UpdateRegulationClusterPayload';
  regulationCluster?: Maybe<RegulationCluster>;
};

export type UpdateRegulationInput = {
  id: Scalars['ID'];
  mainKeywordRefs: Array<EntityRefOfKeywordInput>;
  marketRefs: Array<EntityRefOfMarketInput>;
  name: Scalars['String'];
  regulationNumber: Scalars['String'];
  standardPlusPcmsClusterRefs: Array<EntityRefOfStandardPlusPcmsClusterInput>;
  summary?: InputMaybe<Scalars['String']>;
};

export type UpdateRegulatoryDocumentAttachmentsInput = {
  attachments: Array<AttachmentInput>;
  regulatoryDocumentId: Scalars['ID'];
};

export type UpdateRegulatoryDocumentInput = {
  dateEffective?: InputMaybe<Scalars['Date']>;
  dateExpiration?: InputMaybe<Scalars['Date']>;
  documentReferences?: InputMaybe<Array<DocumentReferenceInput>>;
  documentSourceRef?: InputMaybe<EntityRefOfDocumentSourceInput>;
  documentStatus?: InputMaybe<DocumentStatus>;
  finalVersionRef?: InputMaybe<EntityRefOfRegulatoryDocumentInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  predecessorRef?: InputMaybe<EntityRefOfRegulatoryDocumentInput>;
  regulationRef?: InputMaybe<EntityRefOfRegulationInput>;
  serviceProviderRefs?: InputMaybe<Array<EntityRefOfUserInput>>;
  skipModificationDataUpdate?: InputMaybe<Scalars['Boolean']>;
  status: RegulatoryDocumentStatus;
  successorRef?: InputMaybe<EntityRefOfRegulatoryDocumentInput>;
  summary?: InputMaybe<Scalars['String']>;
  topicManagementSummary?: InputMaybe<TopicManagementSummaryInput>;
  workflow?: InputMaybe<WorkflowInput>;
};

export type UpdateRegulatoryDocumentParagraphBatchInput = {
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
  update: UpdateRegulatoryDocumentParagraphBatchUpdateInput;
};

export type UpdateRegulatoryDocumentParagraphBatchPayload = {
  __typename?: 'UpdateRegulatoryDocumentParagraphBatchPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type UpdateRegulatoryDocumentParagraphBatchUpdateInput = {
  activeTypeMarketRefs?: InputMaybe<Array<EntityRefOfActiveTypeMarketInput>>;
  bodyworkVariantRefs?: InputMaybe<Array<EntityRefOfBodyworkVariantInput>>;
  categoryRefs?: InputMaybe<Array<EntityRefOfCategoryInput>>;
  comprehensive?: InputMaybe<Scalars['Date']>;
  dateNewRegistration?: InputMaybe<Scalars['Date']>;
  dateNewTypes?: InputMaybe<Scalars['Date']>;
  driveVariantRefs?: InputMaybe<Array<EntityRefOfDriveVariantInput>>;
  engineVariantRefs?: InputMaybe<Array<EntityRefOfEngineVariantInput>>;
  gearboxVariantRefs?: InputMaybe<Array<EntityRefOfGearboxVariantInput>>;
  keywordRefs?: InputMaybe<Array<EntityRefOfKeywordInput>>;
  modelYear?: InputMaybe<Scalars['Int']>;
  phaseIn?: InputMaybe<Array<PhaseDateInput>>;
  phaseOut?: InputMaybe<Array<PhaseDateInput>>;
  summary?: InputMaybe<Scalars['String']>;
  tagRefs?: InputMaybe<Array<EntityRefOfTagInput>>;
  vehicleCategoryRefs?: InputMaybe<Array<EntityRefOfVehicleCategoryInput>>;
};

export type UpdateRegulatoryDocumentParagraphInput = {
  paragraphId: Scalars['ID'];
  regulatoryDocumentId: Scalars['ID'];
  update: UpdateRegulatoryDocumentParagraphUpdateInput;
};

export type UpdateRegulatoryDocumentParagraphPayload = {
  __typename?: 'UpdateRegulatoryDocumentParagraphPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type UpdateRegulatoryDocumentParagraphUpdateInput = {
  categoryRefs: Array<EntityRefOfCategoryInput>;
  comprehensive?: InputMaybe<Scalars['Date']>;
  dateNewRegistration?: InputMaybe<Scalars['Date']>;
  dateNewTypes?: InputMaybe<Scalars['Date']>;
  driveVariantRefs: Array<EntityRefOfDriveVariantInput>;
  keywordRefs: Array<EntityRefOfKeywordInput>;
  modelYear?: InputMaybe<Scalars['Int']>;
  phaseIn?: InputMaybe<Array<PhaseDateInput>>;
  phaseOut?: InputMaybe<Array<PhaseDateInput>>;
  summary?: InputMaybe<Scalars['String']>;
  tagRefs: Array<EntityRefOfTagInput>;
  vehicleCategoryRefs: Array<EntityRefOfVehicleCategoryInput>;
};

export type UpdateRegulatoryDocumentParagraphsInput = {
  paragraphUpdates: Array<ParagraphUpdateInput>;
  regulatoryDocumentId: Scalars['ID'];
};

export type UpdateRegulatoryDocumentParagraphsPayload = {
  __typename?: 'UpdateRegulatoryDocumentParagraphsPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type UpdateRegulatoryDocumentStatusInput = {
  documentError?: InputMaybe<Scalars['String']>;
  documentStatus: DocumentStatus;
  regulatoryDocumentId: Scalars['ID'];
};

export type UpdateRegulatoryDocumentStatusPayload = {
  __typename?: 'UpdateRegulatoryDocumentStatusPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type UpdateRequirementAttachmentsInput = {
  attachments: Array<AttachmentInput>;
  requirementId: Scalars['ID'];
};

export type UpdateRequirementInput = {
  activeTypeMarketRefs?: InputMaybe<Array<EntityRefOfActiveTypeMarketInput>>;
  approvalWorkFlow?: InputMaybe<ApprovalWorkFlowInput>;
  bodyworkVariantRefs?: InputMaybe<Array<EntityRefOfBodyworkVariantInput>>;
  category?: InputMaybe<RequirementCategory>;
  changeType?: InputMaybe<RequirementChangeType>;
  definition?: InputMaybe<Scalars['String']>;
  documentReferences?: InputMaybe<Array<DocumentReferenceInput>>;
  driveVariantRefs?: InputMaybe<Array<EntityRefOfDriveVariantInput>>;
  engineVariantRefs?: InputMaybe<Array<EntityRefOfEngineVariantInput>>;
  gearboxVariantRefs?: InputMaybe<Array<EntityRefOfGearboxVariantInput>>;
  id: Scalars['ID'];
  marketRefs?: InputMaybe<Array<EntityRefOfMarketInput>>;
  name: Scalars['String'];
  requirementId?: InputMaybe<Scalars['String']>;
  skipModificationDataUpdate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<RequirementStatus>;
  systemLevelRefs?: InputMaybe<Array<EntityRefOfSystemLevelInput>>;
  tagRefs?: InputMaybe<Array<EntityRefOfTagInput>>;
  vehicleCategoryRefs?: InputMaybe<Array<EntityRefOfVehicleCategoryInput>>;
  version?: InputMaybe<Scalars['String']>;
  vexClusterRefs: Array<EntityRefOfVexClusterInput>;
};

export type UpdateRequirementsVersionsInput = {
  newVersions: Array<Scalars['String']>;
  requirementRefs: Array<EntityRefOfRequirementInput>;
};

export type UpdateRequirementsVersionsPayload = {
  __typename?: 'UpdateRequirementsVersionsPayload';
  int?: Maybe<Scalars['Int']>;
};

export type UpdateSimulationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateStandardPlusPcmsClusterInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateStandardPlusPcmsClusterPayload = {
  __typename?: 'UpdateStandardPlusPcmsClusterPayload';
  standardPlusPcmsCluster?: Maybe<StandardPlusPcmsCluster>;
};

export type UpdateSubscriptionInput = {
  id: Scalars['ID'];
  keywordRefs?: InputMaybe<Array<EntityRefOfKeywordInput>>;
  marketRefs?: InputMaybe<Array<EntityRefOfMarketInput>>;
  name: Scalars['String'];
  notificationFormat: NotificationFormat;
  notificationFrequency: NotificationFrequency;
  optOnlyForMainKeywords?: InputMaybe<Scalars['Boolean']>;
  standardPlusPcmsClusterRefs?: InputMaybe<Array<EntityRefOfStandardPlusPcmsClusterInput>>;
};

export type UpdateSystemLevelInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateSystemLevelPayload = {
  __typename?: 'UpdateSystemLevelPayload';
  systemLevel?: Maybe<SystemLevel>;
};

export type UpdateTagInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  translations?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  tag?: Maybe<Tag>;
};

export type UpdateVehicleCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleCategoryPayload = {
  __typename?: 'UpdateVehicleCategoryPayload';
  vehicleCategory?: Maybe<VehicleCategory>;
};

export type UpdateVehicleProjectInput = {
  activeTypeMarketRefs: Array<EntityRefOfActiveTypeMarketInput>;
  bodyworkVariantRefs: Array<EntityRefOfBodyworkVariantInput>;
  derivative?: InputMaybe<Scalars['String']>;
  detVsi?: InputMaybe<Scalars['DateTime']>;
  driveVariantRefs: Array<EntityRefOfDriveVariantInput>;
  engineVariantRefs: Array<EntityRefOfEngineVariantInput>;
  eopVsi?: InputMaybe<Scalars['DateTime']>;
  euLegislation?: InputMaybe<Scalars['Boolean']>;
  gearboxVariantRefs: Array<EntityRefOfGearboxVariantInput>;
  generation: Scalars['String'];
  id: Scalars['ID'];
  isElectro?: InputMaybe<Scalars['Boolean']>;
  marketRefs: Array<EntityRefOfMarketInput>;
  modelSeries: Scalars['String'];
  modelYear?: InputMaybe<Scalars['Int']>;
  p10Vsi?: InputMaybe<Scalars['DateTime']>;
  posEtVsi?: InputMaybe<Scalars['DateTime']>;
  posVsi?: InputMaybe<Scalars['DateTime']>;
  sopVsi: Scalars['DateTime'];
  sosVsi?: InputMaybe<Scalars['DateTime']>;
  sysgenVsi?: InputMaybe<Scalars['DateTime']>;
  typStartVsi?: InputMaybe<Scalars['DateTime']>;
  wvtaVsi?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateVexClusterAssignmentBatchInput = {
  keywordId: Scalars['ID'];
  keywordStatus: KeywordStatus;
  paragraphIds: Array<Scalars['ID']>;
  regulatoryDocumentId: Scalars['ID'];
  rejectKeywordReason: Scalars['String'];
  rejectReason: Scalars['String'];
  rejectReasonOption: RejectReasonOption;
  suggestedKeywordIds: Array<Scalars['ID']>;
  vexClusterId: Scalars['ID'];
};

export type UpdateVexClusterAssignmentBatchPayload = {
  __typename?: 'UpdateVexClusterAssignmentBatchPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type UpdateVexClusterAssignmentInput = {
  keywordId: Scalars['ID'];
  keywordStatus: KeywordStatus;
  paragraphId: Scalars['ID'];
  regulatoryDocumentId: Scalars['ID'];
  rejectReason: Scalars['String'];
  suggestedKeywordIds: Array<Scalars['ID']>;
  vexClusterId: Scalars['ID'];
};

export type UpdateVexClusterAssignmentPayload = {
  __typename?: 'UpdateVexClusterAssignmentPayload';
  regulatoryDocument?: Maybe<RegulatoryDocument>;
};

export type UpdateVexClusterInput = {
  approverRefs: Array<EntityRefOfUserInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  userRefs: Array<EntityRefOfUserInput>;
};

export type UpdateVexClusterPayload = {
  __typename?: 'UpdateVexClusterPayload';
  vexCluster?: Maybe<VexCluster>;
};

export type UploadRegulatoryDocumentAssetInput = {
  assetId: Scalars['String'];
  contentType?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  regulatoryDocumentId: Scalars['ID'];
};

export type UploadRegulatoryDocumentFileError = DoubletError;

export type UploadRegulatoryDocumentFileInput = {
  contentType?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  fileType?: InputMaybe<RegulatoryDocumentFileType>;
  regulatoryDocumentId: Scalars['ID'];
};

export type User = Node & {
  __typename?: 'User';
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname?: Maybe<Scalars['String']>;
  userPrincipalName?: Maybe<Scalars['String']>;
};

export type UserInput = {
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mail?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  surname?: InputMaybe<Scalars['String']>;
  userPrincipalName?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Pure = 'PURE',
  Readers = 'READERS',
  RegulationReader = 'REGULATION_READER',
  ServiceProvider = 'SERVICE_PROVIDER',
  ShApprover = 'SH_APPROVER',
  SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
  Vex = 'VEX',
  Vko = 'VKO',
  VkoExternal = 'VKO_EXTERNAL'
}

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type VehicleCategory = Entity & NamedEntity & Node & {
  __typename?: 'VehicleCategory';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type VehicleCategorySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type VehicleProject = Entity & NamedEntity & Node & {
  __typename?: 'VehicleProject';
  activeTypeMarkets: Array<ActiveTypeMarket>;
  anonymous: Scalars['Boolean'];
  assignedRequirements: Array<AssignedRequirement>;
  attachments?: Maybe<Array<AttachmentRef>>;
  auditLog: Array<AuditLog>;
  bodyworkVariants: Array<BodyworkVariant>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deletionPeriod: Scalars['Int'];
  derivative?: Maybe<Scalars['String']>;
  detVsi?: Maybe<Scalars['Date']>;
  driveVariants: Array<DriveVariant>;
  engineVariants: Array<EngineVariant>;
  eopVsi?: Maybe<Scalars['Date']>;
  euLegislation?: Maybe<Scalars['Boolean']>;
  gearboxVariants: Array<GearboxVariant>;
  generation: Scalars['String'];
  id: Scalars['ID'];
  isElectro?: Maybe<Scalars['Boolean']>;
  markets: Array<Market>;
  modelSeries: Scalars['String'];
  modelYear?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  p10Vsi?: Maybe<Scalars['Date']>;
  posEtVsi?: Maybe<Scalars['Date']>;
  posVsi?: Maybe<Scalars['Date']>;
  sopVsi?: Maybe<Scalars['Date']>;
  sosVsi?: Maybe<Scalars['Date']>;
  sysgenVsi?: Maybe<Scalars['Date']>;
  typStartVsi?: Maybe<Scalars['Date']>;
  usersWithPermissions: Array<User>;
  willBeAnonymized: Scalars['Boolean'];
  wvtaVsi?: Maybe<Scalars['Date']>;
};

export type VehicleProjectDetailsPageEntity = {
  __typename?: 'VehicleProjectDetailsPageEntity';
  assignedRequirements: Array<AssignedRequirement>;
  derivatives: Array<Scalars['String']>;
  generation: Scalars['String'];
  key: Scalars['String'];
  markets: Array<VehicleProjectDetailsPageMarketsEntity>;
  modelSeries: Scalars['String'];
  paragraphs: Array<RegulatoryDocumentParagraphDetailsPage>;
  regulations: Array<RegulationDetailsPage>;
  sopVsiValues: Array<Scalars['DateTime']>;
  userWPermissions: Array<User>;
  vehicleProjectId: Scalars['String'];
};

export type VehicleProjectDetailsPageMarketsEntity = Entity & {
  __typename?: 'VehicleProjectDetailsPageMarketsEntity';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
};

export type VehicleProjectFilterInput = {
  and?: InputMaybe<Array<VehicleProjectFilterInput>>;
  anonymous?: InputMaybe<BooleanOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletionPeriod?: InputMaybe<IntOperationFilterInput>;
  derivative?: InputMaybe<StringOperationFilterInput>;
  detVsi?: InputMaybe<DateTimeOperationFilterInput>;
  eopVsi?: InputMaybe<DateTimeOperationFilterInput>;
  euLegislation?: InputMaybe<BooleanOperationFilterInput>;
  generation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  isElectro?: InputMaybe<BooleanOperationFilterInput>;
  modelSeries?: InputMaybe<StringOperationFilterInput>;
  modelYear?: InputMaybe<IntOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VehicleProjectFilterInput>>;
  p10Vsi?: InputMaybe<DateTimeOperationFilterInput>;
  posEtVsi?: InputMaybe<DateTimeOperationFilterInput>;
  posVsi?: InputMaybe<DateTimeOperationFilterInput>;
  sopVsi?: InputMaybe<DateTimeOperationFilterInput>;
  sosVsi?: InputMaybe<DateTimeOperationFilterInput>;
  sysgenVsi?: InputMaybe<DateTimeOperationFilterInput>;
  typStartVsi?: InputMaybe<DateTimeOperationFilterInput>;
  willBeAnonymized?: InputMaybe<BooleanOperationFilterInput>;
  wvtaVsi?: InputMaybe<DateTimeOperationFilterInput>;
};

export type VehicleProjectOverviewPageEntity = Entity & {
  __typename?: 'VehicleProjectOverviewPageEntity';
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  generation: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  modelSeries: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  requirementsCount: Scalars['Int'];
};

export type VehicleProjectPayload = {
  __typename?: 'VehicleProjectPayload';
  vehicleProject: VehicleProject;
};

export type VexCluster = Entity & NamedEntity & Node & {
  __typename?: 'VexCluster';
  approverRefs: Array<EntityRefOfUser>;
  approvers: Array<User>;
  attachments?: Maybe<Array<AttachmentRef>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  id: Scalars['ID'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy: User;
  name: Scalars['String'];
  userRefs: Array<EntityRefOfUser>;
  users: Array<User>;
};

export type VexClusterAssignment = {
  __typename?: 'VexClusterAssignment';
  keywordStatus: KeywordStatus;
  rejectKeywordReason?: Maybe<Scalars['String']>;
  rejectReason: Scalars['String'];
  rejectReasonOption: RejectReasonOption;
  vexCluster: VexCluster;
};

export type VexClusterAssignmentFilterInput = {
  and?: InputMaybe<Array<VexClusterAssignmentFilterInput>>;
  keywordStatus?: InputMaybe<KeywordStatusOperationFilterInput>;
  or?: InputMaybe<Array<VexClusterAssignmentFilterInput>>;
  rejectKeywordReason?: InputMaybe<StringOperationFilterInput>;
  rejectReason?: InputMaybe<StringOperationFilterInput>;
  rejectReasonOption?: InputMaybe<RejectReasonOptionOperationFilterInput>;
  vexCluster?: InputMaybe<EntityRefOfVexClusterFilterInput>;
};

export type VexClusterAssignmentInput = {
  keywordStatus: KeywordStatus;
  rejectKeywordReason?: InputMaybe<Scalars['String']>;
  rejectReason: Scalars['String'];
  rejectReasonOption: RejectReasonOption;
  vexCluster: EntityRefOfVexClusterInput;
};

export type VexClusterFilterInput = {
  and?: InputMaybe<Array<VexClusterFilterInput>>;
  approverRefs?: InputMaybe<ListFilterInputTypeOfEntityRefOfUserFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VexClusterFilterInput>>;
  userRefs?: InputMaybe<ListFilterInputTypeOfEntityRefOfUserFilterInput>;
};

export type VexClusterSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type VexWorkbenchItem = {
  __typename?: 'VexWorkbenchItem';
  paragraphCount: Scalars['Int'];
  regulatoryDocument: RegulatoryDocument;
};

export type VkoWorkbenchItem = {
  __typename?: 'VkoWorkbenchItem';
  keywordName: Scalars['String'];
  regulatoryDocumentParagraph: RegulatoryDocumentParagraph;
  rejectKeywordReason: Scalars['String'];
  rejectReason: Scalars['String'];
  vexClusterName: Scalars['String'];
};

export type Workflow = {
  __typename?: 'Workflow';
  clearingTimestamp?: Maybe<Scalars['DateTime']>;
  clearingVKO: Array<User>;
  clearingWithReservationsTimestamp?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dueDateInProcess?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  processingVKO: User;
  processingVKOExternal: User;
  reapprovingMessage?: Maybe<Scalars['String']>;
  reapprovingVKO: User;
  status: WorkflowStatus;
};

export type WorkflowFilterInput = {
  and?: InputMaybe<Array<WorkflowFilterInput>>;
  clearingTimestamp?: InputMaybe<DateTimeOperationFilterInput>;
  clearingWithReservationsTimestamp?: InputMaybe<DateTimeOperationFilterInput>;
  dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  dueDateInProcess?: InputMaybe<DateTimeOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowFilterInput>>;
  reapprovingMessage?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<WorkflowStatusOperationFilterInput>;
};

export type WorkflowInput = {
  clearingTimestamp?: InputMaybe<Scalars['DateTime']>;
  clearingWithReservationsTimestamp?: InputMaybe<Scalars['DateTime']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  dueDateInProcess?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  reapprovingMessage?: InputMaybe<Scalars['String']>;
  status: WorkflowStatus;
};

export type WorkflowSortInput = {
  clearingTimestamp?: InputMaybe<SortEnumType>;
  clearingWithReservationsTimestamp?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  dueDateInProcess?: InputMaybe<SortEnumType>;
  message?: InputMaybe<SortEnumType>;
  reapprovingMessage?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export enum WorkflowStatus {
  Archived = 'ARCHIVED',
  Clearing = 'CLEARING',
  ClearingWithReservations = 'CLEARING_WITH_RESERVATIONS',
  Examination = 'EXAMINATION',
  Finalized = 'FINALIZED',
  InProgressExternal = 'IN_PROGRESS_EXTERNAL',
  InProgressInternal = 'IN_PROGRESS_INTERNAL',
  Modified = 'MODIFIED',
  New = 'NEW',
  QualityControlExternal = 'QUALITY_CONTROL_EXTERNAL',
  QualityControlInternal = 'QUALITY_CONTROL_INTERNAL'
}

export type WorkflowStatusOperationFilterInput = {
  eq?: InputMaybe<WorkflowStatus>;
  in?: InputMaybe<Array<WorkflowStatus>>;
  neq?: InputMaybe<WorkflowStatus>;
  nin?: InputMaybe<Array<WorkflowStatus>>;
};
